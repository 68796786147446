import { defineComponent, onMounted, reactive, ref } from 'vue'
import type { Ref } from 'vue'
import { Form, Field } from 'vee-validate'
import * as Yup from 'yup'
import {
  TypeTerminals
} from '@/utils/interfaces/iCityTerminal'
import InputText from 'primevue/inputtext'
import RadioButton from 'primevue/radiobutton'
import Button from 'primevue/button'
import MapsSearch from '@/views/pages/master-setting/city-terminal/components/MapsSearch.vue'
import rgxExp from '@/utils/helpers/regExp'
import apiUseCase from '@/usecase/apiUseCase'
import returnUrlPrams from '@/utils/helpers/returnUrlPrams'
import { useStore } from 'vuex'

const moduleStore = 'cityTerminal'

export default defineComponent({
  name: 'FormTerminal',
  components: {
    Form,
    MapsSearch,
    Field,
    InputText,
    RadioButton,
    Button
  },
  emits: ['onBack', 'onSubmit'],
  setup(props: any, { emit }: any) {
    const store = useStore()
    const schemaValidation = Yup.object().shape({
      Name: Yup.string()
        .required('Nama Halte/Terminal harus diisi')
        .min(1, 'Nama Halte/Terminal minimal 1 karakter')
        .max(50, 'Nama Halte/Terminal maksimal 50 karakter')
        .matches(rgxExp.notPrefixSpace, 'Nama Halte/Terminal tidak boleh hanya menggunakan spasi')
        .when(['Type'], ([Type], schema) => {
          const afterCheck = schema.test('name-unique', 'Nama Halte/Terminal sudah digunakan.', async (val: any) => {
            if (!val) return true
            try {
              const { result } = await apiUseCase.get(`/management/v1/MsStation${returnUrlPrams({
                search: val,
                filterField: ['name']
              })}`)
              const processArr = result.concat(store.state[moduleStore].itemsTerminal).filter((x: any) => x.IsDeleted === false)
              let filterResult = processArr.filter((x: any) => x.Name === val)
              if (Type) {
                filterResult = processArr.filter((x: any) => x.Name === val && x.Type === Type)
              }
              return filterResult.length === 0
            } catch (error) {
              return false
            }
          })
          return afterCheck
        }),
      Code: Yup.string()
        .required('Kode harus diisi')
        .test('len', 'Kode harus terdiri dari 3 karakter', async (val: any) => val.length === 3)
        .matches(rgxExp.noWhiteSpaceAndSpecialChar, 'Kode Halte/Terminal hanya boleh terdiri dari huruf dan angka')
        .matches(rgxExp.notPrefixSpace, 'Kode Halte/Terminal tidak boleh hanya menggunakan spasi')
        .when(['Type'], ([Type], schema) => {
          const afterCheck = schema.test('code-unique', 'Kode Halte/Terminal sudah digunakan.', async (val: any) => {
            if (!val) return true
            try {
              const { result } = await apiUseCase.get(`/management/v1/MsStation${returnUrlPrams({
                search: val,
                filterField: ['code']
              })}`)
              const processArr = result.concat(store.state[moduleStore].itemsTerminal).filter((x: any) => x.IsDeleted === false)
              let filterResult = processArr.filter((x: any) => x.Code === val)
              if (Type) {
                filterResult = processArr.filter((x: any) => x.Code === val && x.Type === Type)
              }
              return filterResult.length === 0
            } catch (error) {
              return false
            }
          })
          return afterCheck
        }),
      Type: Yup.string().required('Jenis harus diisi'),
      Latlng: Yup.object().required('Lokasi harus diisi')
    })
    const formValues: TypeTerminals = reactive({
      Name: store.state.hjpDataList.modalForm.data.MsCitiesId ? store.state.hjpDataList.modalForm.data.Name : '',
      Code: store.state.hjpDataList.modalForm.data.MsCitiesId ? store.state.hjpDataList.modalForm.data.Code : '',
      Type: store.state.hjpDataList.modalForm.data.MsCitiesId ? store.state.hjpDataList.modalForm.data.Type : '',
      Description: store.state.hjpDataList.modalForm.data.MsCitiesId ? store.state.hjpDataList.modalForm.data.Description : '',
      Latlng: store.state.hjpDataList.modalForm.data.MsCitiesId ? { lat: store.state.hjpDataList.modalForm.data.Latitude, lng: store.state.hjpDataList.modalForm.data.Longitude } : null
    })
    const address: Ref<string> = ref('')
    const modalTitle = ref('Tambah')
    const onSubmit = (values: any) => {
      console.log('submit', values)
      emit('onSubmit', values)
    }
    const onBack = () => {
      emit('onBack')
    }
    const changeValueType = (e: any) => {
      console.log('change e type', e)
    }
    const optionType = [{
      label: 'Halte',
      value: 'Halte'
    },
    {
      label: 'Terminal',
      value: 'Terminal'
    }]
    const getLocationFromMaps = (val: any) => {
      address.value = val.formatted_address
    }

    onMounted(() => {
      console.log('store', store.state)
      if (store.state.hjpDataList.modalForm.data.MsCitiesId) {
        modalTitle.value = 'Edit'
      } else {
        modalTitle.value = 'Tambah'
      }
    })
    return {
      onSubmit,
      schemaValidation,
      formValues,
      optionType,
      changeValueType,
      onBack,
      getLocationFromMaps,
      address,
      modalTitle
    }
  }
})
