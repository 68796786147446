import { defineComponent, ref, watch, onMounted, computed, getCurrentInstance, reactive } from 'vue'
import type { Ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import apiUseCase from '@/usecase/apiUseCase'
import moment from 'moment'
import IResponse from '@/utils/interfaces/iResponse'

// Components
// import HjpDataList from '@/views/components/list/HjpDataList.vue'
import Card from 'primevue/card'
import Button from 'primevue/button'
import InputSwitch from 'primevue/inputswitch'
import OverlayPanel from 'primevue/overlaypanel'
import ListActions from '@/views/components/list/ListActions.vue'
import InputIconRight from '@/views/components/form/InputIconRight.vue'
import HjpConfirmationModal from '@/views/components/modal/HjpConfirmationModal.vue'
import ApproveDepositHeader from '@/views/pages/approve-deposit/components/DepositHeader.vue'
import StatusApprovalOptions from '@/views/pages/approve-deposit/components/StatusApprovalOptions.vue'
import SearchOptions from '@/views/pages/approve-deposit/components/SearchOptions.vue'
import DateFilterOptions from '@/views/pages/approve-deposit/components/DateFilterOptions.vue'
import EmptyState from '@/views/components/list/EmptyState.vue'
import Paginator from '@/views/components/paginator/Paginator.vue'
import ApproveModal from '@/views/pages/approve-deposit/components/ApproveModal.vue'
import Popper from 'vue3-popper'
import currencyNumberFormat from '@/utils/helpers/rupiahFormat'
import { isNull } from 'lodash'

const moduleStore = 'approvePp'

const ApproveDepositTable = defineComponent({
  name: 'ApproveDepositTable',
  components: {
    Card,
    InputSwitch,
    OverlayPanel,
    ListActions,
    InputIconRight,
    HjpConfirmationModal,
    ApproveDepositHeader,
    StatusApprovalOptions,
    SearchOptions,
    DateFilterOptions,
    EmptyState,
    Paginator,
    ApproveModal,
    Popper,
    Button
  },
  setup() {
    const app = getCurrentInstance()
    const {
      $toast
    } = app!.appContext.config.globalProperties
    const router = useRouter()
    const route = useRoute()
    // const store = useStore()
    const op = ref()
    const store = useStore()
    const inputSearch = ref('')
    const placeholder = ref('Cari Disini...')
    const showDetail = ref(false)
    const isApproved = ref(null) as any
    const selectedStatus = computed({
      get: () => store.state[moduleStore].selectedStatus,
      set: (value) => {
        store.dispatch('approvePp/setSelectedStatus', value)
      }
    })
    const selectedId = ref(null)
    const showDetailId = ref(null)
    const selection = ref(0)
    const tooltipText = ref('Terdapat Perubahan Pengeluaran Di Rit Yang Belum Dikonfirmasi Oleh Kondektur')
    const isShowApproveDialog = ref(false)
    // const selectedStatus = ref({
    //   name: 'Semua Status',
    //   val: 0
    // })
    // const selectedStatus = ref(0)
    const selectedSearch = ref({
      name: 'Id Transaksi',
      val: 0
    })

    const paginate = reactive({
      pageNumber: 1,
      pageSize: 10,
      totalRecords: 0,
    })
    const endpoint = 'transaction/v1/TransactionPp'
    const baseRoute = 'approve-deposits'
    const dataSource = ref([]) as any
    const dataRow = ref(null) as any

    const rangeDate = ref({
      // start: moment().toDate(),
      // end: moment().add(7, 'day').toDate(),
      start: null,
      end: null
    }) as any

    const mappingDataSource = (data: any) => {
      const temp = ref()
      dataSource.value = []
      // eslint-disable-next-line array-callback-return
      data.map((x: any) => {
        x.showedTypes = x.Details.length > 2 ? x.Details.slice(0, 2) : x.Details
        x.countHiddenTypes = x.Details.length > 2 ? x.Details.length - 2 : 0
        temp.value = x
        dataSource.value.push(temp.value)
      })
    }

    const setDataRowOnRedux = () => {
      console.log('dataRow', dataRow.value)
      store.dispatch('storeRowDataTable', dataRow.value)
    }

    const attrModalConfirmation = reactive({
      show: false,
      title: 'Approve Setoran?',
      subTitle: 'Apakah anda yakin ingin melakukan Approve Setoran?',
      btnSubmitTitle: 'Approve Setoran',
      imgContent: null
    })

    const getAllData = () => {
      store.dispatch('showLoading')
      const arrParams = []
      let paramsString = ''

      if (!isNull(isApproved.value)) {
        arrParams.push(`isApproved=${isApproved.value}`)
      }
      if (selectedSearch.value.val === 0) {
        arrParams.push(`trxId=${inputSearch.value}`)
      }
      if (!isNull(rangeDate.value.start)) {
        console.log('dateRange', rangeDate.value)
        arrParams.push(`startDate=${moment(rangeDate.value.start.toString()).format('YYYY-MM-DD')}`)
      }
      if (!isNull(rangeDate.value.end)) {
        console.log('dateRange', rangeDate.value)
        arrParams.push(`endDate=${moment(rangeDate.value.end.toString()).format('YYYY-MM-DD')}`)
      }
      if (paginate.pageNumber > 0) {
        arrParams.push(`pageNumber=${paginate.pageNumber}`)
      }
      if (paginate.pageSize > 0) {
        arrParams.push(`pageSize=${paginate.pageSize}`)
      }
      arrParams.push('orderBy=IsApproved asc')
      paramsString = `?${arrParams.join('&')}`
      apiUseCase.get(`${endpoint}/Deposit${paramsString}`).then((response) => {
        // console.log('response', response)
        // let isCanApprove = false
        const dataSourceTemp = response.result
        dataSource.value = dataSourceTemp.map((x: any) => ({
          ...x,
          // eslint-disable-next-line no-use-before-define
          IsCanApprove: getIsAllApproveRits(x)
        }))
        console.log('dataSource', dataSource)
        paginate.totalRecords = response.count
        // console.log('paginate', paginate)
        // isLoading.value = false
      }).catch((err: any) => {
        // isLoading.value = false
      })
      store.dispatch('hideLoading')
    }

    const showDropDown = (evt: any, item: any) => {
      console.log('item', evt)
      selection.value = item
      // dataRow.value = item
      op.value.toggle(evt)
    }

    const getIsAllApproveRits = (x: any) => {
      let val = true
      // for (let i = 0; i < x.Rits.length; i++) {
      //   if (!x.Rits[i].IsAnyExpenseRevisions) {
      //     val = true
      //     break
      //   } else if (x.Rits[i].ExpenseRevisionsIsApproved && x.Rits[i].IsAnyExpenseRevisions) {
      //     val = true
      //     break
      //   } else {
      //     break
      //   }
      // }
      for (let i = 0; i < x.Rits.length; i++) {
        console.log(x.Rits[i].ExpenseRevisionsIsApproved)
        if (x.Rits[i].IsAnyExpenseRevisions && !x.Rits[i].ExpenseRevisionsIsApproved) {
          val = false
          break
        }
      }
      return val
    }

    // const onDelete = () => {
    //   console.log('onDelete')
    //   setDataRowOnRedux()
    //   attrModalConfirmation.show = true
    // }

    // const onEdit = () => {
    //   console.log('onEdit')
    //   setDataRowOnRedux()
    //   router.push({
    //     path: `/${baseRoute}/${dataRow.value.Id}`
    //   })
    // }

    const onSearch = (value: any) => {
      // console.log('onSearch', value)
      inputSearch.value = value
      getAllData()
    }

    const hideDialogConfirmation = () => {
      attrModalConfirmation.show = false
    }

    const onSubmitConfirmation = (val: any = '') => {
      console.log('submitFromDialog')
      console.log('val', val)
      store.dispatch('showLoading')
      // const deletedId = val === '' ? dataRow.value.Id : val
      apiUseCase.postOrPut(`${endpoint}/Approve-Deposit`, selectedId.value, null).then((res: IResponse) => {
        console.log('response approve', res)
        if (res.error) {
          $toast.add({
            severity: 'error',
            // summary: '',
            detail: 'Gagal melakukan Approve Setoran',
            group: 'bc',
            closable: false,
            life: 3000
          })
        } else {
          $toast.add({
            severity: 'success',
            // summary: 'Berhasil melakukan Approve Setoran',
            detail: 'Berhasil melakukan Approve Setoran',
            group: 'bc',
            closable: false,
            life: 3000
          })
          isShowApproveDialog.value = false
          getAllData()
        }
      }).catch((err: any) => {
        console.log('err delete', err)
      })
      hideDialogConfirmation()
      store.dispatch('hideLoading')
    }

    const changeIsActive = async (val: any) => {
      store.dispatch('showLoading')
      console.log('val', val)
      const data = {
        // Id: val.Id,
        IsActive: val.IsActive,
        IsDelete: val.IsDeleted
      }
      const response = await apiUseCase.patch(endpoint, val.Id, data)
      if (!response.error) {
        $toast.add({
          severity: 'success',
          detail: 'Data berhasil diubah',
          group: 'bc',
          life: 3000
        })
      } else {
        $toast.add({
          severity: 'error',
          detail: 'Data gagal diubah',
          group: 'bc',
          life: 3000
        })
      }
      store.dispatch('hideLoading')
    }

    const openDetail = (id: any) => {
      // showDetail.value = !showDetail.value
      showDetailId.value = showDetailId.value === id ? null : id
    }

    const showDetailPage = (id: any) => {
      router.push({
        // path: baseRoute.value
        name: `${baseRoute}-detail`,
        params: {
          id
        }
      })
    }

    const checkStatusApproval = () => {
      if (selectedStatus.value === 1) {
        isApproved.value = true
      } else if (selectedStatus.value === 2) {
        isApproved.value = false
      } else {
        isApproved.value = null
      }
      getAllData()
    }

    const selectStatusApproval = (evt: any, val: any) => {
      selectedStatus.value = val.val
      checkStatusApproval()
      console.log(selectedStatus.value)
      op.value.toggle(evt)
      // getAllData()
    }

    const selectSearchOption = (evt: any, val: any) => {
      selectedSearch.value = val
      console.log(selectedSearch.value)
      op.value.toggle(evt)
    }

    const onFilterDateRange = (evt: any, val: any) => {
      console.log('dateRange', val)
      rangeDate.value = val
      op.value.toggle(evt)
      getAllData()
    }

    const onChangePage = (evt: any) => {
      store.dispatch('showLoading')
      paginate.pageNumber = evt.page + 1
      // console.log('obj pagin', paginate)
      getAllData()
      // const container = $('.wrap-hjp-data-list')
      // $(window).scrollTop(container.offset().top)
    }

    const approveAct = (id: any) => {
      store.dispatch('showLoading')
      console.log('id', id)
      selectedId.value = id
      isShowApproveDialog.value = true
      console.log('isShow', isShowApproveDialog.value)
    }

    const hideApproveDialog = () => {
      isShowApproveDialog.value = false
    }

    onMounted(() => {
      console.log('state', store.state)
      // getAllData()
      checkStatusApproval()
    })

    return {
      showDropDown,
      op,
      // onDelete,
      // onEdit,
      inputSearch,
      placeholder,
      onSearch,
      dataSource,
      attrModalConfirmation,
      onSubmitConfirmation,
      hideDialogConfirmation,
      changeIsActive,
      openDetail,
      showDetail,
      showDetailId,
      showDetailPage,
      moment,
      currencyNumberFormat,
      selection,
      selectedStatus,
      selectStatusApproval,
      selectedSearch,
      selectSearchOption,
      rangeDate,
      onFilterDateRange,
      paginate,
      onChangePage,
      approveAct,
      hideApproveDialog,
      isShowApproveDialog,
      selectedId,
      tooltipText
    }
  }
})

export default ApproveDepositTable
