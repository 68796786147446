import {
  ref, watch, defineComponent, PropType, toRefs, reactive, computed, onMounted, getCurrentInstance
} from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

import Card from 'primevue/card'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import HeaderHjpTable from '@/views/components/header/HeaderHjpTable.vue'
import Paginator from '@/views/components/paginator/Paginator.vue'
import ListActions from '@/views/components/list/ListActions.vue'
import HjpSideBar from '@/views/components/sidebar/HjpSideBar.vue'
import HjpCofirmModal from '@/views/components/modal/HjpConfirmationModal.vue'
import OverlayPanel from 'primevue/overlaypanel'
import IResponse from '@/utils/interfaces/iResponse'
import HjpTabView from '@/views/components/tab/HjpTabView.vue'
import InputIconRight from '@/views/components/form/InputIconRight.vue'
import TypePassengerForm from '@/views/pages/master-setting/price/active-price/components/PassengerTypeForm.vue'
import apiUseCase from '@/usecase/apiUseCase'
import moment from 'moment'
import Button from 'primevue/button'
import { encryptDecriptMethods } from '@/plugins/encryptDecriptMethods'
import 'jspreadsheet-ce/dist/jspreadsheet.css'
import jexcel from 'jspreadsheet-ce'
import PriceAdjustmentForm from '@/views/pages/master-setting/price/active-price/components/PriceAdjustmentForm.vue'
import OriginDestinationForm from '@/views/pages/master-setting/price/active-price/components/OriginDestinationForm.vue'
import { log } from 'console'
import ActivePriceForm from '@/views/pages/master-setting/price/active-price/Form.vue'
import $ from 'jquery'

export default {
  components: {
    Card,
    Button,
    TypePassengerForm,
    HjpSideBar,
    PriceAdjustmentForm,
    OriginDestinationForm,
    HjpCofirmModal,
    OverlayPanel,
    ListActions,
    ActivePriceForm,
    // Sidebar
    // Accordion,
    // AccordionTab
  },
  setup() {
    // const test = ref(true)
    const route = useRoute() as any
    const router = useRouter()
    const store = useStore()
    const dataPrice = ref() as any
    const isLoading = ref(false)
    const endpoint = '/management/v1/MsPrice'
    const endpointDetail = '/management/v1/MsDetilPrice'
    const open = ref(false)
    const priceId = encryptDecriptMethods.decrypt(route.params.id)
    const priceChangeId = ref(null)
    const pricePassengerId = ref(null)
    const priceChange = ref([]) as any
    const pricePassenger = ref([]) as any
    const pricePassengerTickets = ref([]) as any
    const selectedToActivatePriceId = ref(null)
    const dataRow = ref(null) as any
    const deleteSwitch = ref(0)
    const op = ref()
    const formSwitch = ref(0)
    const app = getCurrentInstance()
    const {
      $toast, $strInd
    } = app!.appContext.config.globalProperties

    const refExcel = ref()
    // const dataExcel = ref([
    //   {}
    // ])

    const attrModalConfirmation = reactive({
      show: false,
      title: 'Hapus Harga?',
      subTitle: '',
      btnSubmitTitle: 'Hapus Harga',
      imgContent: require('@/assets/img/modal-confirmation/price-add-update.svg')
    })
    const dataDefault = ref([['', '', '', '', '', '', '', ''], ['', '', '', '', '', '', '', '']]) as any
    const dataExcel = ref([['', '', '', '', ''], ['', '', '', '', '']]) as any
    const dataTemp = ref([]) as any
    const dataFormExcel = ref([]) as any
    const titleHeaderTable = ref({})
    const fromIdPrice = ref(null)
    const dataFormActivatePrice = reactive({
      isActive: true,
      basisId: '' as any
    })
    const jExcelOptions = ref({
      data: dataExcel.value,
      hideIndex: true,
      allowInsertColumn: false,
      allowDeleteColumn: false,
      allowDeleteRow: false,
      allowInsertRow: false,
      contextMenu: false,
      editable: true,
      columns: [
        { readOnly: true, wordWrap: true },
        { readOnly: true, wordWrap: true },
        { mask: '#.##' },
        { mask: '#.##' },
        { mask: '#.##', readOnly: true },
      ],
      nestedHeaders: [
        [
          {
            title: 'Asal/Tujuan',
            colspan: '2',
          },
          {
            title: 'Harga Tiket',
            colspan: '1',
          },
          {
            title: 'Komisi Kondektur',
            colspan: '1',
          },
          {
            title: 'Masuk Perusahaan',
            colspan: '1',
          },
        ]
      ],
      mergeCells: {
        // A3: [1, 3],
        // A6: [1, 3]
      },
      // maxDimensions: [5, 5],
      columnSorting: false,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onchange: (instance: any, cell: any, x: any, y: any, value: any) => { },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onselection: (instance: any, cell: any, x: any, y: any, value: any) => { }
    })
    const jExcelObj = ref(null) as any
    const currentId = ref(null) as any
    const loadingExcel = ref(false)
    const showSubmitExcel = ref(false)
    const currentCategoryDrop = ref(null) as any
    const originList = ref([]) as any

    const onTableChange = (instance: any, cell: any, x: any, y: any, value: any) => {
      // eslint-disable-next-line eqeqeq
      const xTemp = x == 2 ? 3 : 2
      let valueTicket = jExcelObj.value.getValueFromCoords(xTemp === 2 ? xTemp : x, y)
      let valueCommision = jExcelObj.value.getValueFromCoords(xTemp === 3 ? xTemp : x, y)
      valueTicket = valueTicket < 0 ? 0 : valueTicket
      valueCommision = valueCommision < 0 ? 0 : valueCommision
      let income = (valueTicket - valueCommision) < 0 ? 0 : (valueTicket - valueCommision)
      const isValid = (valueTicket - valueCommision) >= 0
      // console.log('isValid', isValid)
      // console.log('x', x)
      // console.log('y', y)
      // console.log('xTemp', xTemp)
      // console.log('valueCommission', valueCommision)
      // console.log('valueTicket', valueTicket)

      if (!isValid) {
        if (valueCommision > valueTicket) {
          jExcelObj.value.setValueFromCoords(3, y, valueTicket)
        } else {
          jExcelObj.value.setValueFromCoords(x, y, 0)
        }
        $toast.add({
          severity: 'error',
          detail: 'Komisi tidak boleh lebih besar dari Harga Tiket',
          group: 'bc',
          closable: false,
          life: 3000
        })
        if (valueCommision > valueTicket || valueCommision < 0 || valueTicket < 0) {
          valueTicket = 0
          valueCommision = 0
          income = 0
        }
      } else {
        if (!showSubmitExcel.value) {
          showSubmitExcel.value = true
        }
        jExcelObj.value.ignoreEvents = true
        if (!Number.isNaN(Number(value))) {
          if (value < 0) {
            jExcelObj.value.setValue(cell, 0)
          } else {
            jExcelObj.value.setValue(cell, Number(value))
          }
        } else {
          jExcelObj.value.setValue(cell, '')
        }
        jExcelObj.value.setValueFromCoords(4, y, Number(income), true)
        jExcelObj.value.ignoreEvents = false
      }
    }

    const initExcel = () => {
      const docs = document.getElementById('xxx')
      console.log('docsbefore', document.getElementById('xxx'))
      // if (docs?.classList.contains('jexcel_container')) {
      //   jExcelObj.value.destroy()
      // }
      jExcelOptions.value.onchange = onTableChange
      jExcelObj.value = jexcel(docs, jExcelOptions.value)
      // jExcelObj.setMerge('A1', 1, 2)
      jExcelObj.value.hideIndex()
      jExcelObj.value.hideRow(1)
      console.log('docsafter', document.getElementById('xxx'))
      // jExcelObj.value.hideRow(2)
      // jExcelObj.value.onchange = onTableChange
    }

    const destroyExcel = () => {
      jExcelObj.value.destroy()
      dataExcel.value = dataDefault.value
    }

    const changeOpen = () => {
      open.value = !open.value
      if (open.value) {
        setTimeout(initExcel, 3000)
      }
    }

    const loadExcel = () => {
      setTimeout(initExcel, 300)
    }

    const showModalForm = computed({
      get: () => store.state.hjpDataList.modalForm.show,
      set: (value) => {
        store.dispatch('setShowModalFormDl', value)
      }
    })

    const hideModalForm = () => {
      showModalForm.value = false
    }

    const onClickBtnAdd = (val: any) => {
      console.log('onClickBtnAdd', val)
      store.dispatch('flushRowDataTable')
      // store.dispatch('setTitleFormSidebarDl', 'anu')
      showModalForm.value = true
      console.log('store', store.state.hjpDataList.modalForm)
    }

    const showForm = (data: any, type: any = 0) => {
      console.log('showForm', [data, type])
      formSwitch.value = type
      store.dispatch('setShowModalFormDl', true)
      store.dispatch('storeRowDataTable', data)
      store.dispatch('setSelectedPassenger', pricePassengerId.value)
      store.dispatch('setSelectedPriceChange', priceChangeId.value)
    }

    const showAdjustmentForm = (data: any) => {
      console.log('showForm', data)
      formSwitch.value = 1
      store.dispatch('setShowModalFormDl', true)
      store.dispatch('storeRowDataTable', data)
    }

    const showOriginDestinationForm = (data: any) => {
      console.log('showForm', data)
      formSwitch.value = 2
      store.dispatch('setShowModalFormDl', true)
      store.dispatch('storeRowDataTable', data)
      store.dispatch('setSelectedPassenger', pricePassengerId.value)
      store.dispatch('setSelectedPriceChange', priceChangeId.value)
    }

    const showMasterPriceForm = (data: any) => {
      console.log('showForm', data)
      formSwitch.value = 4
      store.dispatch('setShowModalFormDl', true)
      store.dispatch('storeRowDataTable', data)
    }

    const getData = async () => {
      await apiUseCase.get(`${endpoint}/${priceId}`).then((response) => {
        console.log('res', response)
        dataPrice.value = response.result
        console.log('dataX', dataPrice)
        store.dispatch('ui/setBreadcrumbs', [
          {
            title: 'Master Harga',
            routeName: 'master-price'
          },
          {
            title: `Detail Harga ${response.result.Name}`,
            routeName: 'master-price-detail'
          }
        ])
        isLoading.value = true
      })
    }

    const getPriceChanges = async () => {
      await apiUseCase.get(`${endpointDetail}/price-changes/${priceId}`).then((response) => {
        console.log('resPriceChange', response)
        priceChange.value = response.result
        const priceChangeTemp = priceChange.value.filter((x: any) => x.IsActive === true)
        priceChangeId.value = priceChangeTemp[0].Id
        console.log('priceChangeId', priceChangeId.value)
        // eslint-disable-next-line no-use-before-define
        getListPassenger()
      })
    }

    const getListPassenger = async () => {
      await apiUseCase.get(`${endpointDetail}/passangers/${priceChangeId.value}`).then((response) => {
        // console.log('resListPassenger', response)
        pricePassenger.value = response.result
        if (response.result.length > 0) {
          pricePassengerId.value = pricePassenger.value[0].IdPassenger
        }
        // eslint-disable-next-line no-use-before-define
        showTableList(response.result.length)
        // console.log('pricePassengerId', pricePassengerId.value)
      })
      // eslint-disable-next-line no-use-before-define
      getPassengerTicket()
    }

    const getPassengerTicket = async () => {
      // jExcelObj.value.destroy()
      $('#xxx').html('')
      originList.value = []
      // console.log('dataDefault', dataDefault.value)
      dataTemp.value = [['', '', '', '', '', '', '', ''], ['', '', '', '', '', '', '', '']]
      // console.log('dataExcelTemp', dataTemp.value)
      // destroyExcel()
      // dataExcel.value = dataExcel.value.slice(0, 2)
      // loadExcel()
      let passengerTicketPrice = [] as any
      let passengerTicketPriceTemp = [] as any
      const params = `id=${priceChangeId.value}&passenger=${pricePassengerId.value}`
      // console.log('dataExcel1', dataExcel.value)
      await apiUseCase.get(`${endpointDetail}/passangers-ticket?${params}`).then((response) => {
        // console.log('resPassengerTicket', response)
        dataExcel.value = dataExcel.value.slice(0, 2)
        // console.log('dataExcel', dataExcel.value)
        // eslint-disable-next-line array-callback-return
        response.result.map((x: any, index: any) => {
          if (index > 0) {
            passengerTicketPrice = [x.OriginName, x.DestinationName, x.Bruto, x.Commision, x.Net]
            passengerTicketPriceTemp = [x.Id, x.OriginName, x.Origin, x.DestinationName, x.Destination, x.Bruto, x.Commision, x.Net]
            // console.log('passengerTicketPriceTemp', passengerTicketPriceTemp)
            dataExcel.value.push(passengerTicketPrice)
            console.log('xxx')
            dataTemp.value.push(passengerTicketPriceTemp)
            if (!originList.value.includes(x.Origin)) {
              originList.value.push(x.Origin)
            }
          }
        })
        console.log('originList', originList.value)
        // eslint-disable-next-line array-callback-return
        isLoading.value = true
        // loadExcel()
        console.log('docs', document.getElementById('xxx'))
        initExcel()
        jExcelObj.value.setData((dataExcel.value.length >= 2) ? dataExcel.value : [{}])
        // dataExcel.value = dataExcel.value.slice(0, 2)
        // console.log('dataExcel', dataExcel.value)
        // console.log('dataExcelTemp', dataTemp.value)
      })
    }

    const changeSelectedPriceChange = (id: any) => {
      console.log('priceChangeId', id)
      priceChangeId.value = id
      jExcelObj.value.destroy()
      getListPassenger()
    }

    const changeSelectedPassengerChange = (id: any) => {
      console.log('id', id)
      deleteSwitch.value = 0
      pricePassengerId.value = id
      jExcelObj.value.destroy()
      getPassengerTicket()
    }

    const showTableList = (passengersLength: any = 0) => {
      console.log('woohoo', passengersLength)
      const tableList = document.getElementById('table-view')
      const defaultView = document.getElementById('default-view')

      // if (tableList?.classList.contains('hidden') || passengersLength < 1) {
      if (passengersLength > 0) {
        // eslint-disable-next-line no-unused-expressions
        defaultView?.classList.add('hidden')
        // eslint-disable-next-line no-unused-expressions
        tableList?.classList.remove('hidden')
      } else {
        // eslint-disable-next-line no-unused-expressions
        defaultView?.classList.remove('hidden')
        // eslint-disable-next-line no-unused-expressions
        tableList?.classList.add('hidden')
      }
    }

    const processSubmitDataExcel = async () => {
      const toastMessage = 'Harga berhasil disimpan!'
      const param = `?passengerId=${pricePassengerId.value}`
      const response = await apiUseCase.postOrPut(`${endpointDetail}`, priceChangeId.value, dataFormExcel.value, param)
      console.log('response', response)
      if (!response.error) {
        store.dispatch('setShowModal', false)
        store.dispatch('setReloadDataList', true)
        // store.dispatch('setSelectedPassenger', dataForm.msPassengerId[0])
        $toast.add({
          severity: 'success',
          detail: toastMessage,
          group: 'bc',
          closable: false,
          life: 3000
        })
        hideModalForm()
        // store.dispatch('setReloadDataTable')
      } else {
        $toast.add({
          severity: 'error',
          detail: response.message,
          group: 'bc',
          closable: false,
          life: 3000
        })
      }
    }

    const submitDataExcel = () => {
      const value = jExcelObj.value.getData()
      value.splice(0, 2)
      dataTemp.value.splice(0, 2)
      // eslint-disable-next-line array-callback-return
      value.map((data: any, index: any) => {
        dataTemp.value[index].splice(5, 3, data[2], data[3], data[4])
      })
      // eslint-disable-next-line array-callback-return
      dataTemp.value.map((x: any) => {
        const data = {
          id: x[0],
          origin: x[2],
          destination: x[4],
          bruto: x[5],
          commission: x[6],
          net: x[7]
        }
        dataFormExcel.value.push(data)
      })
      processSubmitDataExcel()
    }

    const activatePriceChange = (id: any) => {
      console.log('id', id)
      selectedToActivatePriceId.value = id
      attrModalConfirmation.title = 'Aktifkan Perubahan Harga?'
      attrModalConfirmation.subTitle = 'Pastikan data harga sudah benar dan sesuai dengan yang Anda inginkan.'
      attrModalConfirmation.btnSubmitTitle = 'Aktifkan Harga'
      attrModalConfirmation.show = true
    }

    const processActivatePriceChange = async () => {
      store.dispatch('showLoading')
      const toastMessage = 'Harga berhasil diaktifkan!'
      dataFormActivatePrice.basisId = priceId
      const response = await apiUseCase.patch(`${endpointDetail}/active-price`, selectedToActivatePriceId.value, dataFormActivatePrice)
      console.log('response', response)
      if (!response.error) {
        store.dispatch('setShowModal', false)
        store.dispatch('setReloadDataList', true)
        // store.dispatch('setSelectedPassenger', dataForm.msPassengerId[0])
        $toast.add({
          severity: 'success',
          detail: toastMessage,
          group: 'bc',
          closable: false,
          life: 3000
        })
        // eslint-disable-next-line no-use-before-define
        hideConfirmModal()
        // store.dispatch('setReloadDataTable')
      } else {
        $toast.add({
          severity: 'error',
          detail: response.message,
          group: 'bc',
          closable: false,
          life: 3000
        })
        // eslint-disable-next-line no-use-before-define
        hideConfirmModal()
      }
      store.dispatch('hideLoading')
    }

    const processDeletePrice = () => {
      console.log('deletePrice')
      store.dispatch('showLoading')
      const toastMessage = 'Harga berhasil dihapus!'
      apiUseCase.delete(endpoint, dataRow.value?.Id).then((res: IResponse) => {
        console.log('response delete', res)
        if (res.error) {
          $toast.add({
            severity: 'error',
            summary: 'Tidak dapat menghapus Harga',
            detail: res.message,
            group: 'bc',
            closable: false,
            life: 3000
          })
        } else {
          $toast.add({
            severity: 'success',
            summary: 'Berhasil menghapus Harga!',
            detail: 'Harga sudah dihapus oleh sistem dan tidak dapat digunakan kembali.',
            group: 'bc',
            closable: false,
            life: 3000
          })
          // eslint-disable-next-line no-use-before-define
          hideConfirmModal()
          store.dispatch('hideLoading')
          router.push({
            name: 'master-price',
          })
        }
      }).catch((err: any) => {
        console.log('err delete', err)
      })
      // eslint-disable-next-line no-use-before-define
      hideConfirmModal()
      store.dispatch('hideLoading')
    }

    const processSubmitModal = () => {
      if (deleteSwitch.value === 1) {
        processDeletePrice()
      } else {
        processActivatePriceChange()
      }
    }

    const hideConfirmModal = () => {
      attrModalConfirmation.show = false
    }

    const deleteCategory = async () => {
      console.log('delete cuy')
      await apiUseCase.delete(`${endpointDetail}`, `${priceChangeId.value}`, `?passengerId=${pricePassengerId.value}`).then((response) => {
        console.log('res', response)
        if (!response.error) {
          store.dispatch('setReloadDataList', true)
          $toast.add({
            severity: 'success',
            summary: 'Berhasil menghapus Perubahan Harga!',
            detail: 'Harga sudah dihapus oleh sistem dan tidak dapat digunakan kembali.',
            group: 'bc',
            closable: false,
            life: 3000
          })
          hideModalForm()
          // store.dispatch('setReloadDataTable')
        } else {
          $toast.add({
            severity: 'error',
            detail: response.message,
            group: 'bc',
            closable: false,
            life: 3000
          })
        }
      })
    }

    const showDropDown = (evt: any, item: any) => {
      console.log('evt', evt)
      dataRow.value = item
      op.value.toggle(evt)
    }

    const setDataRowOnRedux = async () => {
      store.dispatch('storeRowDataListDl', dataRow.value)
    }

    const onEdit = async () => {
      await setDataRowOnRedux()
      showMasterPriceForm(dataRow.value)
      op.value.hide()
    }

    const onDelete = () => {
      setDataRowOnRedux()
      deleteSwitch.value = 1
      console.log('delete', deleteSwitch.value)
      attrModalConfirmation.title = 'Hapus Harga?'
      attrModalConfirmation.subTitle = 'Menghapus Harga akan berdampak kepada kondektur dan trayek bus yang sudah diassign, Tetap hapus Harga?'
      attrModalConfirmation.btnSubmitTitle = 'Hapus Harga'
      attrModalConfirmation.imgContent = require('@/assets/img/modal-confirmation/delete-default.svg')
      attrModalConfirmation.show = true
      op.value.hide()
    }

    watch(() => store.state.hjpDataList.reloadData, (val) => {
      if (val) {
        console.log('reloading')
        store.dispatch('showLoading')
        // console.log('jExcelObj', jExcelObj.value)
        if (jExcelObj.value) {
          jExcelObj.value.destroy()
        }
        getData()
        getPriceChanges()
        // getPassengerTicket()
        // if (pricePassenger.value > 0) {
        // }
        showTableList()
        store.dispatch('hideLoading')
      }
    })

    onMounted(() => {
      store.dispatch('showLoading')
      console.log('id', encryptDecriptMethods.decrypt(route.params.id))
      getData()
      getPriceChanges()
      // getListPassenger()
      // loadExcel()
      // getPassengerTicket()
      // initExcel()
      // isLoading.value = true
      // console.log('store', store.state.hjpDataList.modalForm)
      // console.log('passengerCount', pricePassenger.value.length)
      store.dispatch('hideLoading')
    })

    return {
      dataPrice,
      moment,
      isLoading,
      changeOpen,
      open,
      onClickBtnAdd,
      hideModalForm,
      showModalForm,
      showForm,
      showAdjustmentForm,
      formSwitch,
      showOriginDestinationForm,
      priceChange,
      priceChangeId,
      changeSelectedPriceChange,
      pricePassenger,
      pricePassengerId,
      changeSelectedPassengerChange,
      submitDataExcel,
      activatePriceChange,
      attrModalConfirmation,
      hideConfirmModal,
      processActivatePriceChange,
      deleteCategory,
      showDropDown,
      onEdit,
      onDelete,
      op,
      processDeletePrice,
      deleteSwitch,
      processSubmitModal,
      originList
    }
  }
}
