import { App } from 'vue'
import * as mqtt from 'mqtt'
import mitt from 'mitt'
import environment from '@/configs/environtment'
import { useStore } from 'vuex'
import store from '@/store'

export default {
  install: (app: App<Element>) => {
    let client = null as any
    // const store = useStore()

    const socketHub = mitt()
    app.config.globalProperties.$socketHub = socketHub
    const option = {
      username: environment.getUsernameMqtt(),
      password: environment.getPasswordMqtt(),
      // username: 'agus',
      // password: 'P@ssw0rd',
      // clientId: store.state.appActiveUser.guid,
      // connectTimeout: 5000,
      reconnectPeriod: 10000,
      protocol: environment.getProtocolBaseMqtt(),
      hostname: environment.getUrlBaseMqtt(),
      port: environment.getPortBaseMqtt(),
      // path: '/mqtt',
      ssl: true,
      clean: true,
      keepalive: 60
    } as any
    // console.log('optionmqt', option)
    const url = `${environment.getProtocolBaseMqtt()}://${environment.getUrlBaseMqtt()}:${environment.getPortBaseMqtt()}/ws`
    // console.log(mqtt.connect(option))
    // client = mqtt.connect(option)
    // console.log('udah connect', client)
    try {
      // client = mqtt.connect(option)
      client = mqtt.connect(url, option)
      // client = mqtt.connect(option)
      // console.log('udah connect', client)
      if (client.connected) {
        // console.log('tidak bisa connect')
      } else if (!client.connected) {
        // console.log('koneksi berhasil')
      }
    } catch (error: any) {
      // console.log('mqtt.connect error', error)
    }

    client.on('connect', (connack: any) => {
      // console.log('Connection MQTT succeeded!', connack)
      client.subscribe('rit/#')

      client.on('message', (topic: any, message: any) => {
        console.log('test', [topic, message.toString()])
        let temp = null
        if (topic.includes('rit') === true) {
          console.log('state', store.state)
          // socketHub.emit('rit', JSON.parse(message.toString()))
          temp = JSON.parse(message.toString())
          if (temp.ritOrder === 'firstRit') {
            store.dispatch('setReloadRit1Dashboard')
          } else if (temp.ritOrder === 'secondRit') {
            store.dispatch('setReloadRit2Dashboard')
          } else {
            console.log('oops', temp)
          }
        } else if (topic.includes('expense') === true) {
          temp = JSON.parse(message.toString())
          store.dispatch('approvePp/setReloadApprovePp', temp.trxId)
        }
        // client.publish('RekanKerja/9pzez/12', 'Bariq kirim publish after aziz send')
        // client.end()
      })

      client.subscribe('expense/#')

      client.on('message', (topic: any, message: any) => {
        console.log('test', [topic, message.toString()])
        let temp = null
        if (topic.includes('expense') === true) {
          temp = JSON.parse(message.toString())
          store.dispatch('approvePp/setReloadApprovePp', temp.trxId)
        }
        // client.publish('RekanKerja/9pzez/12', 'Bariq kirim publish after aziz send')
        // client.end()
      })
    })

    client.on('error', (params: any) => {
      console.log('ini error', params)
    })

    // client.subscribe('lokasikurir/#')
    // client.on('message', (topic: any, message: any) => {
    //   // socketHub.emit('RekanKerja', message.toString())
    //   switch (topic) {
    //     case 'lokasikurir':
    //       console.log(`masuk diluar => ${message} topic ${topic}`)
    //       // code block
    //       break
    //     default:
    //     // code block
    //   }
    //   // console.log(`Bariq => ${message} from topic ${topic}`)
    //   // client.publish('RekanKerja/9pzez/12', 'Bariq kirim publish after aziz send')
    // })

    // // Tambahkan Topic Yang Perlu Di Subcribe
    // client.subscribe('karepmu')
    // client.subscribe('topiic')
  }
}
