
import { computed, defineComponent, getCurrentInstance, onMounted, reactive, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { Form, Field, FieldArray, ErrorMessage } from 'vee-validate'
import apiUseCase from '@/usecase/apiUseCase'
import moment from 'moment'
import * as Yup from 'yup'
import Card from 'primevue/card'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Checkbox from 'primevue/checkbox'
import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown'
import Button from 'primevue/button'
import { DatePicker } from 'v-calendar'
import vSelect from 'vue-select'
import SelectFetch from '@/views/components/form/SelectAsync.vue'
import HjpTabView from '@/views/components/tab/HjpTabView.vue'
import HjpAlertModal from '@/views/components/modal/HjpAlertModal.vue'
import KondekturActiveScheduleList from '@/views/pages/plotting-conductor/components/KondekturActiveScheduleList.vue'
import PariwisataRosterDialog from '@/views/pages/roster/components/DatePickerPariwisataDialog.vue'
import KondekturLog from '@/views/pages/plotting-conductor/components/KondekturLog.vue'
import HjpCofirmModal from '@/views/components/modal/HjpConfirmationModal.vue'
import SelectConductorForm from '@/views/pages/plotting-conductor/form/SelectKondektur.vue'
import returnUrlPrams from '@/utils/helpers/returnUrlPrams'

export default defineComponent({
  name: 'VerbalReport',
  components: {
    Card,
    Form,
    Field,
    FieldArray,
    SelectFetch,
    // InputText,
    Button,
    HjpTabView,
    PariwisataRosterDialog,
    // KondekturActiveScheduleList,
    // KondekturLog,
    // DatePicker,
    // vSelect,
    HjpCofirmModal,
    HjpAlertModal
    // SelectConductorForm
  },
  setup() {
    const endpointTrayek = '/management/v1/Trayek'
    const endpointRoster = '/roster/v1/Roster'
    const activityLogEndpoint = '/management/v1/ActivityLog'
    const endpointTransactionPp = 'transaction/v1/TransactionPp'

    const app = getCurrentInstance()
    const {
      $icon,
      $toast
    } = app!.appContext.config.globalProperties
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const icon = $icon
    const defaultValue = ref(
      {
        vehicle: null,
        kondektur: null,
        driver: null,
        helper: null
      }
    )
    const initialValue = ref({
      data: [
        {
          vehicle: null,
          kondektur: null,
          driver: null,
          helper: null
        }
      ]
    })
    const schema = Yup.object().shape({
      data: Yup.array().of(
        Yup.object().shape({
          vehicle: Yup.object().required('Bus tidak boleh kosong'),
          kondektur: Yup.object().required('Kondektur tidak boleh kosong'),
          driver: Yup.object().required('Sopir tidak boleh kosong'),
        })
      ).test('is-unique-bus', 'Bus tidak boleh ada yang sama', (val) => {
        console.log('val', val)
        if (!Array.isArray(val)) {
          return true
        }
        const set = new Set()
        // console.log('set', set)
        for (let i = 0; i < val.length; i++) {
          if (val[i]) {
            // console.log(val[i])
            const temp: any = val[i]
            // console.log('xxx', temp.nopol?.Id)
            // console.log('val', val[i])
            // console.log('aaaa', [i, val[i], set, set.has(temp.Id)])
            if (set.has(temp.vehicle?.Id) && temp.vehicle) {
              // console.log('exist')
              return false
            }
            set.add(temp.vehicle?.Id)
          }
        }
        return true
      }).test('is-unique-kondektur', 'Kondektur tidak boleh ada yang sama', (val) => {
        // console.log('val', val)
        if (!Array.isArray(val)) {
          return true
        }
        const set = new Set()
        // console.log('set', set)
        for (let i = 0; i < val.length; i++) {
          if (val[i]) {
            // console.log(val[i])
            const temp: any = val[i]
            // console.log('xxx', temp.nopol?.Id)
            // console.log('val', val[i])
            // console.log('aaaa', [i, val[i], set, set.has(temp.Id)])
            if (set.has(temp.kondektur?.Id) && temp.kondektur) {
              // console.log('exist')
              return false
            }
            set.add(temp.kondektur?.Id)
          }
        }
        return true
      })
        .test('is-unique-driver', 'Sopir tidak boleh ada yang sama', (val) => {
          // console.log('val', val)
          if (!Array.isArray(val)) {
            return true
          }
          const set = new Set()
          // console.log('set', set)
          for (let i = 0; i < val.length; i++) {
            if (val[i]) {
              // console.log(val[i])
              const temp: any = val[i]
              // console.log('xxx', temp.nopol?.Id)
              // console.log('val', val[i])
              // console.log('aaaa', [i, val[i], set, set.has(temp.Id)])
              if (set.has(temp.driver?.Id) && temp.driver) {
                // console.log('exist')
                return false
              }
              set.add(temp.driver?.Id)
            }
          }
          return true
        })
    })
    const tabHeader = ref([
      { title: 'Log Bus', value: 0 }
    ])
    const activeTab = ref(0)
    const isLoading = ref(true)
    const dataForm = ref([]) as any
    const showConfirmDialog = ref(false)
    const selectedBus = ref(null) as any
    const showForm = ref(false)
    const currentSchedules = ref([]) as any
    const logBus = ref() as any
    const confirmDialog = {
      title: 'Simpan Jadwal Pariwisata?',
      subTitle: 'Pastikan jadwal yang Anda tambahkan sudah sesuai dan tidak ada perubahan ditengah-tengah.',
      btnSubmitTitle: 'Simpan Jadwal Pariwisata',
      imgContent: require('@/assets/img/modal-confirmation/schedule.svg')
    }

    const modalConfirmation = reactive({
      show: false,
      imgContent: require('@/assets/img/modal-confirmation/roster-confirmation.svg'),
      title: '',
      subTitle: '',
      btnSubmitTitle: 'OK',
      subTitleHtml: false,
      isDelete: false
    })

    const hideDialogConfirmation = () => {
      console.log('hideDialog')
      showConfirmDialog.value = false
    }

    const showDialogConfirmation = () => {
      console.log('hideDialog')
      showConfirmDialog.value = true
    }

    const submitFromDialog = () => {
      apiUseCase.postWithNoId(`${endpointRoster}/pariwisata`, dataForm.value)
        .then(({ error, message }) => {
          if (!error) {
            hideDialogConfirmation()
            store.dispatch('setReloadDataList', true)
            store.dispatch('setShowModalFormDl', false)
            // backToListPage()
            $toast.add({
              severity: 'success',
              detail: 'Roster Pariwisata berhasil disimpan',
              group: 'bc',
              closable: false,
              life: 3000
            })
            router.push({
              name: 'roster',
            })
          } else {
            store.dispatch('setShowModalFormDl', false)
            $toast.add({
              severity: 'error',
              detail: message,
              group: 'bc',
              closable: false,
              life: 3000
            })
          }
          store.dispatch('hideLoading')
        })
    }

    const tabsChange = (val: any) => {
      console.log('val', val)
      activeTab.value = val
    }

    const getBusLog = async () => {
      await apiUseCase.get(`${activityLogEndpoint}/VehicleLogs?nopol=${selectedBus.value.Nopol}`).then((response) => {
        console.log('res', response)
        logBus.value = response.result.slice(0, 5)
        console.log('dataX', logBus.value)
      })
    }

    const setCurrVehicleInfo = (val: any, id: any) => {
      console.log('aaaX', val, id)
      selectedBus.value = val
      if (selectedBus.value) {
        getBusLog()
      }
    }

    const onSubmit = (val: any) => {
      console.log('onSubmit', val)
      // console.log('condectur', currentKondektur.value)
      val.data.forEach((data: any) => {
        const dataFormTemp = {
          startDate: `${moment(store.state.roster.dateRange.start).format('YYYY-MM-DD')}`,
          endDate: `${moment(store.state.roster.dateRange.end).format('YYYY-MM-DD')}`,
          departureTime: '00:00:00',
          condecturId: data.kondektur.Id,
          condecturUsername: data.kondektur.Username,
          condecturName: data.kondektur.Fullname,
          msVehicleId: data.vehicle.Id,
          regNumber: data.vehicle.Nopol,
          trayekId: data.vehicle.Trayek.Id,
          trayekName: data.vehicle.Trayek.Name,
          trayekCode: data.vehicle.Trayek.Code,
          trayekTypeId: data.vehicle.TrayekType.Id,
          trayekTypeCode: data.vehicle.TrayekType.Code,
          trayekTypeName: data.vehicle.TrayekType.Name,
          trayekSubId: null,
          msStatusRosterId: 'de4d44be-17c0-40fa-11dd-08dbe0152a30',
          trayekClassName: null,
          statusId: null,
          statusName: null,
          statusRemark: null,
          isTol: data.vehicle.Trayek.IsTol,
          arrivalTime: '23:59:59',
          ppOrder: 0,
          driverId: data.driver.Id,
          driverName: data.driver.Fullname,
          helperId: data?.helper?.Id ?? null,
          helperName: data?.helper?.Fullname ?? null
        }

        console.log('dataForm', dataFormTemp)
        dataForm.value.push(dataFormTemp)
      })
      showDialogConfirmation()
    }

    const showFormDialog = computed({
      get: () => store.state.headerButton.isShowDialogForm,
      set: (val) => {
        store.dispatch('setHeaderButtonActionShowDialogForm', val)
      }
    })

    const openFormDialog = () => {
      showFormDialog.value = true
      console.log('storeAfter', store.state)
    }

    const showFormField = () => {
      showForm.value = true
    }

    const onUpdateDriver = async (val: any) => {
      store.dispatch('showLoading')
      console.log(val)
      // console.log('params', [val, moment(date.toDateString()).format('YYYY-MM-DD')])
      // {{ `${moment(store.state.roster.dateRange.start.toString()).format('DD MMMM YYYY')} - ${moment(store.state.roster.dateRange.end.toString()).format('DD MMMM YYYY')}` }}
      const dateStart = moment(store.state.roster.dateRange.start.toString()).format('YYYY-MM-DD')
      const dateEnd = moment(store.state.roster.dateRange.end.toString()).format('YYYY-MM-DD')
      currentSchedules.value = []
      try {
        const { result, error, message } = await apiUseCase.get(`${endpointRoster}/Driver/${val.value}${returnUrlPrams({
          custom: [['startDate', '=', dateStart], ['endDate', '=', dateEnd]]
        })}`)
        if (!error) {
          if (result.DriverRosterCount > 0) {
            modalConfirmation.title = `Sopir ${result.DriverName} Sudah Mempunyai Jadwal`
            modalConfirmation.subTitle = 'Sopir sudah mempunyai jadwal, mohon cek jadwal sopir berikut ini : '
            modalConfirmation.show = true
            currentSchedules.value = result.DriverRosters
            console.log('sch', currentSchedules.value)
          }
        } else {
          $toast.add({
            severity: 'error',
            detail: message,
            group: 'bc',
            closable: false,
            life: 3000
          })
        }
      } catch (error) {
        console.log('err', error)
      }
      store.dispatch('hideLoading')
    }

    const onUpdateCondectur = async (val: any) => {
      store.dispatch('showLoading')
      console.log(val)
      // console.log('params', [val, moment(date.toDateString()).format('YYYY-MM-DD')])
      // {{ `${moment(store.state.roster.dateRange.start.toString()).format('DD MMMM YYYY')} - ${moment(store.state.roster.dateRange.end.toString()).format('DD MMMM YYYY')}` }}
      const dateStart = moment(store.state.roster.dateRange.start.toString()).format('YYYY-MM-DD')
      const dateEnd = moment(store.state.roster.dateRange.end.toString()).format('YYYY-MM-DD')
      currentSchedules.value = []
      try {
        const { result, error, message } = await apiUseCase.get(`${endpointRoster}/Condectur/${val.value}${returnUrlPrams({
          custom: [['startDate', '=', dateStart], ['endDate', '=', dateEnd]]
        })}`)
        const transaction = await apiUseCase.get(`${endpointTransactionPp}/Condectur-Transaction-Closed/${val.value}`)
        if (!error) {
          if (result.CondecturRosterCount > 0) {
            modalConfirmation.title = `Kondektur ${result.CondecturName} Sudah Mempunyai Jadwal`
            modalConfirmation.subTitle = 'Kondektur sudah mempunyai jadwal, mohon cek jadwal kondektur berikut ini : '
            modalConfirmation.show = true
            currentSchedules.value = result.CondecturRosters
          } else if ((transaction?.result?.TransactionPp ?? []).length > 1) {
            modalConfirmation.title = 'Kondektur belum setor pendapatan'
            modalConfirmation.subTitle = 'Kondektur mempunyai setoran yang belum di approve oleh admin. Mohon cek kembali setoran kondektur lalu approve apabila kondektur sudah setor pendapatan'
            modalConfirmation.show = true
            modalConfirmation.btnSubmitTitle = 'Kembali'
            modalConfirmation.imgContent = require('@/assets/img/modal-confirmation/delete-failed.svg')
          }
        } else {
          $toast.add({
            severity: 'error',
            detail: message,
            group: 'bc',
            closable: false,
            life: 3000
          })
        }
      } catch (error) {
        console.log('err', error)
      }
      store.dispatch('hideLoading')
    }

    const hideConfirmDialog = () => {
      modalConfirmation.show = false
    }

    onMounted(() => {
      isLoading.value = true
      if (!store.state.roster.dateRange.start || !store.state.roster.dateRange.end) {
        router.push({
          name: 'roster',
        })
      } else {
        console.log('aaaa')
        isLoading.value = false
      }
    })

    return {
      initialValue,
      defaultValue,
      icon,
      tabHeader,
      activeTab,
      tabsChange,
      moment,
      schema,
      isLoading,
      onSubmit,
      hideDialogConfirmation,
      confirmDialog,
      showConfirmDialog,
      submitFromDialog,
      openFormDialog,
      store,
      setCurrVehicleInfo,
      selectedBus,
      showForm,
      showFormField,
      logBus,
      currentSchedules,
      hideConfirmDialog,
      onUpdateDriver,
      modalConfirmation,
      onUpdateCondectur
    }
  }
})
