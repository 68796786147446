
import apiUseCase from '@/usecase/apiUseCase'
import { IVerpal, IVerpalMedia } from '@/utils/interfaces/iVerpal'
import { defineComponent, getCurrentInstance, onMounted, reactive, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { StatusVerpal } from '@/utils/enum/StatusVerpal'

// Components
import HjpCofirmModal from '@/views/components/modal/HjpConfirmationModal.vue'
import Card from 'primevue/card'
import Button from 'primevue/button'
import InlineSvg from 'vue-inline-svg'
import environment from '@/configs/environtment'
import SilentBoxSingle from '@/views/components/lightbox/SilentBoxSingle.vue'

import { useGeolocation } from '@vueuse/core'
import { Loader } from '@googlemaps/js-api-loader'
import $ from 'jquery'

import geohash from 'latlon-geohash'

const GOOGLE_MAPS_API_KEY = 'AIzaSyBeIU_SpMpwm43tEK-y0XvWCZtPFwxFzsQ'

const endpoint = '/transaction/v1/Verpal'
const routeNameBack = 'verpal-report'

type IVerpalMediaMap = IVerpalMedia & {
  src: string,
  filename: string,
  alt: string,
  download?: boolean
}
type IVerpalDetail = Omit<IVerpal, 'VerpalMedia'> & {
  VerpalMedia: IVerpalMediaMap[]
}

export default defineComponent({
  name: 'VerpalDetail',
  components: {
    Card,
    Button,
    InlineSvg,
    HjpCofirmModal,
    SilentBoxSingle
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const dataDetail = ref<IVerpalDetail>()
    const lightBox = ref()
    const selectedId = ref()
    const app = getCurrentInstance()
    const {
      $toast, $strInd
    } = app!.appContext.config.globalProperties
    const markerCoordinate = ref({
      long: 0,
      lat: 0
    })
    const dataGeoHash = ref('')

    const loader = new Loader({
      apiKey: GOOGLE_MAPS_API_KEY,
      version: 'weekly',
      libraries: ['places']
    })

    let icon = reactive({
      url: '',
      scaledSize: '' as any
    }) as any
    let geocoder = null as any
    let mapX = null as any
    let marker = null as any

    const attrModalConfirmation = reactive({
      show: false,
      title: 'Approve Verpal?',
      subTitle: 'Pastikan verpal sudah sesuai dengan ketentuan dan peraturan yang berlaku pada Harapan Jaya!',
      btnSubmitTitle: 'Approve Verpal',
      imgContent: require('@/assets/img/modal-confirmation/appove-confirm.svg')
    })

    const getDetail = () => {
      apiUseCase.get(`${endpoint}/${route.params.id}`).then(({ error, result }) => {
        if (!error) {
          const resultMap: IVerpal = result
          dataGeoHash.value = result.GeoHash
          const mapData: IVerpalDetail = {
            ...resultMap,
            VerpalMedia: resultMap.VerpalMedia.map((img) => ({
              ...img,
              filename: img.FileName ? `${environment.getApiBaseUrl()}${img.FileName}` : '',
              src: img.FileName ? `${environment.getApiBaseUrl()}${img.FileName}` : '',
              alt: `verpal-${img.VerpalId}`
            }))
          }
          dataDetail.value = mapData
          store.dispatch('ui/setBreadcrumbs', [
            {
              title: 'Laporan Verpal',
              routeName: routeNameBack
            },
            {
              title: dataDetail.value?.TransactionPp.RosterCode,
              routeName: `${route.name as string}`
            }
          ])
          // eslint-disable-next-line no-use-before-define
          encodeGeo()
        }
      })
    }

    const backToList = () => {
      router.push({
        name: 'verpal-report',
      })
    }

    const approveAct = (id: any) => {
      selectedId.value = id
      attrModalConfirmation.show = true
    }

    const hideDialogConfirmation = () => {
      attrModalConfirmation.show = false
    }

    const onSubmitConfirmation = async () => {
      store.dispatch('showLoading')
      try {
        const response = await apiUseCase.postOrPut(`${endpoint}/Approve`, selectedId.value, null)
        if (!response.error) {
          // store.dispatch('setShowModal', false)
          // store.dispatch('setReloadDataTable', true)
          backToList()
          $toast.add({
            severity: 'success',
            detail: 'Approve Verpal berhasil',
            group: 'bc',
            closable: false,
            life: 3000
          })
        } else {
          $toast.add({
            severity: 'error',
            detail: response.message,
            group: 'bc',
            closable: false,
            life: 3000
          })
        }
      } catch (error) {
        $toast.add({
          severity: 'error',
          detail: 'Approve Verpal gagal. Silahkan coba beberapa saat lagi',
          group: 'bc',
          closable: false,
          life: 3000
        })
      }
      hideDialogConfirmation()
      store.dispatch('hideLoading')
    }

    const initMap = () => {
      loader.load().then(() => {
        icon = {
          url: require('@/assets/img/icon/geocode-icon.svg'),
          // eslint-disable-next-line no-undef
          scaledSize: new google.maps.Size(40, 40)
        }
        // eslint-disable-next-line no-undef
        geocoder = new google.maps.Geocoder()
        // eslint-disable-next-line no-undef
        mapX = new google.maps.Map(document.getElementById('mapDiv') as HTMLElement, {
          center: {
            lat: markerCoordinate.value.lat,
            lng: markerCoordinate.value.long
          },
          zoom: 15,
        })
        // eslint-disable-next-line no-undef
        marker = new google.maps.Marker({
          map: mapX,
          // draggable: true,
          position: {
            lat: markerCoordinate.value.lat,
            lng: markerCoordinate.value.long
          },
          icon
        })
      })
    }

    const encodeGeo = () => {
      const coordinateTemp = geohash.decode(dataGeoHash.value)
      markerCoordinate.value.long = coordinateTemp.lon
      markerCoordinate.value.lat = coordinateTemp.lat
      initMap()
    }

    onMounted(() => {
      getDetail()
      // initMap()
      // encodeGeo()
    })
    return {
      dataDetail,
      lightBox,
      attrModalConfirmation,
      approveAct,
      hideDialogConfirmation,
      onSubmitConfirmation,
      StatusVerpal
    }
  }
})
