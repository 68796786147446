
import { ref, onMounted, reactive, defineComponent, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'

import Button from 'primevue/button'
import apiUseCase from '@/usecase/apiUseCase'
import HjpModalDefault from '@/views/components/modal/ModalDefault.vue'
import ConfirmModal2 from '@/views/components/modal/ConfirmModal2.vue'

export default defineComponent({
  name: 'VehicleTable',
  components: {
    Button,
    HjpModalDefault,
    ConfirmModal2
  },
  setup() {
    const endpointVehicle = '/management/v1/MsVehicle'
    const store = useStore()
    const app = getCurrentInstance()
    const {
      $toast
    } = app!.appContext.config.globalProperties
    const mutationReview = ref(store.state.vehicleMutation)
    const showConfirmDialog = ref(false)
    const isShowAlertDialog = ref(false)
    const attrModalConfirmation = reactive({
      show: false,
      title: '',
      subTitle: '',
      btnSubmitTitle: '',
      imgContent: require('@/assets/img/modal-confirmation/change-bus-confirmation.svg'),
    })

    const hideDialogForm = () => {
      isShowAlertDialog.value = false
    }

    const showSameBusAlertDialog = () => {
      attrModalConfirmation.title = 'Mutasi Gagal'
      attrModalConfirmation.subTitle = 'Tidak dapat melakukan mutasi bus dengan nopol yang sama. Silahkan pilih bus yang lain.'
      attrModalConfirmation.imgContent = require('@/assets/img/modal-confirmation/delete-failed.svg')
      isShowAlertDialog.value = true
    }

    const showAlertDialog = () => {
      attrModalConfirmation.title = 'Masih Ada Jadwal Aktif'
      attrModalConfirmation.subTitle = 'Kendaraan yang Anda pilih saat ini memiliki jadwal aktif. Silakan nonaktifkan dahulu pada penjadwalan'
      attrModalConfirmation.imgContent = require('@/assets/img/modal-confirmation/change-bus-confirmation.svg')
      isShowAlertDialog.value = true
    }

    const submitFromDialog = async () => {
      store.dispatch('showLoading')
      try {
        const payload = {
          msVehicleIdOrigin: mutationReview.value.firstBus.Id,
          msVehicleIdDestination: mutationReview.value.secondBus.Id
        }
        const {
          error, message
        } = await apiUseCase.putWithNoId(`${endpointVehicle}/switch-vehicle`, payload)
        if (!error) {
          $toast.add({
            severity: 'success',
            summary: 'Berhasil',
            detail: `Kendaraan berhasil dipindahkan pada Trayek ${mutationReview.value.secondBus.TrayekDetail.Trayek.Name}`,
            group: 'bc',
            closable: false,
            life: 3000
          })
          store.dispatch('setReloadTrayek')
          store.dispatch('setReloadDataTable', true)
          store.dispatch('setShowDialog', false)
        } else {
          $toast.add({
            severity: 'error',
            summary: 'Gagal',
            detail: message,
            group: 'bc',
            closable: false,
            life: 3000
          })
        }
        // hideDialogConfirmation()
        store.dispatch('hideLoading')
      } catch (error) {
        store.dispatch('hideLoading')
      }
    }

    const onSubmit = async () => {
      store.dispatch('showLoading')
      try {
        const payload = {
          msVehicleIdOrigin: mutationReview.value.firstBus.Id,
          msVehicleIdDestination: mutationReview.value.secondBus.Id
        }
        if (payload.msVehicleIdDestination === payload.msVehicleIdOrigin) {
          showSameBusAlertDialog()
        } else {
          const {
            error, message
          } = await apiUseCase.postOrPut(`${endpointVehicle}/validate-switch-vehicle`, null, payload)
          if (!error) {
            // showConfirmDialog.value = true
            submitFromDialog()
          } else {
            showAlertDialog()
          }
        }
        // hideDialogConfirmation()
        store.dispatch('hideLoading')
      } catch (error) {
        store.dispatch('hideLoading')
      }
    }

    const refConfirmModal = ref<InstanceType<typeof ConfirmModal2>>()
    const showDialogConfirmation = () => {
      // eslint-disable-next-line no-unused-expressions
      refConfirmModal.value?.openModal({
        title: 'Apakah Anda Yakin Ingin Memutasi Kendaraan ?',
        subTitle: 'Pastikan data yang Anda masukkan sudah benar sebelum tersimpan',
        btnSubmitTitle: 'Simpan',
        imgContent: require('@/assets/img/modal-confirmation/vehicle-mutation-confirm.svg')
      }).then((isConfirm) => {
        if (isConfirm) {
          onSubmit()
        }
      })
    }

    const prevPage = () => {
      store.dispatch('setVehicleMutationFormStep', 2)
    }

    onMounted(() => {
      console.log('mutationReview', store.state.vehicleMutation)
    })

    return {
      mutationReview,
      onSubmit,
      showConfirmDialog,
      attrModalConfirmation,
      submitFromDialog,
      prevPage,
      isShowAlertDialog,
      hideDialogForm,
      showDialogConfirmation,
      refConfirmModal
    }
  }
})
