/* eslint-disable */
import { Response } from '@/utils/helpers/response'
import {
  Remote as RemoteHjex,
  remoteEnum
} from '@/data/remote/remoteHjex'
import Repository from '@/data/repository'
import apiOtp from '@/configs/api_otp'
import apiOtpToken from '@/configs/api_otp_token'
import store from '@/store'
import {
  IPayloadOtp
} from '@/utils/interfaces/iUserResponse'
import environment from '@/configs/environtment'

class UserUseCase {
  getGlobalSettingHjex(): Promise<any> {
    return new Repository((new RemoteHjex(remoteEnum.get, '/setting/v1/GlobalSettings')), null).getResult(false)
  }

  replacedPhoneMember(phone: string): string {
    const replaced: string = '62' + phone.slice(1)
    return replaced
  }

  requestOtp(value: IPayloadOtp): Promise<any> {

    console.log('value', value)
    // console.log('token', environment.getOtpApiKey)
    // const token = `hjex2:${environment.getOtpApiKey}`
    const token = value.token
    const encodedToken = Buffer.from(token).toString('base64')
    const data = {
      to: this.replacedPhoneMember(value.phoneNumber),
      type: 'OfficialWhatsapp',
      expirationTime: 120
    }
    return apiOtp({
      method: 'post',
      url: 'otp/v1/requests',
      data,
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
  }

  validateOtp(value: IPayloadOtp): Promise<any> {
    // const token = `hjex2:${environment.getOtpApiKey}`
    const token = value.token
    const encodedToken = Buffer.from(token).toString('base64')
    const data = {
      id: value.id,
      code: value.otp
    }
    return apiOtp({
      method: 'put',
      url: `otp/v1/requests/${value.id}/validate`,
      data,
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
  }

  sendWhatsapp(value: any): Promise<any> {
    console.log('value', value)
    // console.log('token', environment.getOtpApiKey)
    // const token = `hjex2:${environment.getOtpApiKey}`
    const token = 'hjex2:41af6491-0b9f-42eb-94cc-bc488617cb70'
    const encodedToken = Buffer.from(token).toString('base64')
    const data = {
      token: store.getters.globalSettingHjex.tokenOtp.Value,
      mobile: this.replacedPhoneMember(value.phoneNumber),
      // companyToken: environment.getOtpCompanyAuthToken,
      companyToken: '62919543-4ff0-4bfe-b52c-03dfbf330223',
      message: value.message,
      isBroadcast: true,
      type: 'Whatsapp Official',
    }
    return apiOtp({
      method: 'post',
      url: 'v2/OneTimePassword/OTP/send-message',
      data,
      headers: {
        'Authorization': `Basic ${encodedToken}`
      }
    })
  }

  loginClientCredentialsHjex(): Promise<any> {
    console.log('tryLogin', environment.getOtpTokenBaseUrl())
    const qs = require('qs')
    const data = {
      grant_type: environment.getOtpGrantType(),
      // client_id: environment.getOtpClientId(),
      // client_secret: environment.getOtpClientSecret(),
      scope: environment.getOtpScope()
    }
    const basicAuth = {
      username: environment.getOtpClientId(),
      password: environment.getOtpClientSecret()
    }
    const header = {
      'content-type': 'application/x-www-form-urlencoded'
    }
    return apiOtpToken({
      method: 'post',
      url: 'oauth2/token',
      data: qs.stringify(data),
      headers: header,
      auth: basicAuth
    })
    // return new Repository(new RemoteHjex(remoteEnum.post, '/oauth2/token', data, header), null).getResult(false)
    // return new Repository(new Remote(remoteEnum.post, `${path}`, data), null).getResult(false)
  }
}

const userUseCase = new UserUseCase()

export {
  userUseCase
}
