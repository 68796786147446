
import { defineComponent, computed, reactive, onMounted, getCurrentInstance, toRefs } from 'vue'
import InputText from 'primevue/inputtext'

import { useGeolocation } from '@vueuse/core'
import { Loader } from '@googlemaps/js-api-loader'
import $ from 'jquery'

const GOOGLE_MAPS_API_KEY = 'AIzaSyBeIU_SpMpwm43tEK-y0XvWCZtPFwxFzsQ'

const loader = new Loader({
  apiKey: GOOGLE_MAPS_API_KEY,
  version: 'weekly',
  libraries: ['places']
})

export default defineComponent({
  components: {
    InputText
  },
  props: {
    existCurrPos: {
      type: Object,
      default: () => null
    },
    existLat: {
      type: String,
      default: null
    },
    existLng: {
      type: String,
      default: null
    }
  },
  emits: ['sendDataLocation'],
  setup(props: any, { emit }: any) {
    const { coords } = useGeolocation()
    const app = getCurrentInstance()
    const {
      existCurrPos,
      existLat,
      existLng
    } = toRefs(props)
    const {
      $getLocation
    } = app!.appContext.config.globalProperties
    const currPos = computed(() => ({
      lat: 0,
      lng: 0
    }))

    let icon = reactive({
      url: '',
      scaledSize: '' as any
    }) as any
    let geocoder = null as any
    let map = null as any
    let marker = null as any
    /* eslint-disable */

    const geocodeLatLngAndMarkPan = (place: any, geocoder: any, infowindow: any) => {
      const latlng = place.geometry.location

      marker = new google.maps.Marker({
        map,
        icon,
        title: place.name,
        position: latlng,
      })
      infowindow.open({
        anchor: marker,
        map,
      })
      map.setZoom(15)
      emit('sendDataLocation', {
        formatted_address: place.formatted_address,
        latLng: place.geometry.location
      })
    }

    const contentString = '<div class="content">Gunakan Lokasi Saat Ini' +
      '</div>'

    const getInformationLtLg = (ltlng: any) => {
      geocoder.geocode({
        location: ltlng
      }).then((response: any) => {
        if (response.results.length > 0) {
          const result = response.results[0]
          emit('sendDataLocation', {
            formatted_address: result.formatted_address,
            latLng: result.geometry.location
          })
        }
      })
    }

    const initMap = () => {
      console.log('testx', [existLat.value, existLng.value])
      loader.load().then(() => {
        icon = {
          url: require('@/assets/img/icon/geocode-icon.svg'),
          scaledSize: new google.maps.Size(40, 40)
        }
        geocoder = new google.maps.Geocoder()
        map = new google.maps.Map(document.getElementById('mapDiv') as HTMLElement, {
          center: {
            // lat: coords.value.latitude,
            lng: currPos.value.lng,
            lat: currPos.value.lat,
            // lng: Number(existLng.value)
          },
          zoom: 15,
        })
        marker = new google.maps.Marker({
          map: map,
          // draggable: true,
          position: {
            // lat: coords.value.latitude,
            lng: currPos.value.lng,
            lat: currPos.value.lat,
            // lng: Number(existLng.value)
          },
          icon
        })

        if (marker) {
          getInformationLtLg({
            // lat: coords.value.latitude,
            lng: currPos.value.lng,
            lat: currPos.value.lat,
            // lng: Number(existLng.value)
          })
        }

        const infowindow = new google.maps.InfoWindow({
          content: contentString,
        })
        google.maps.event.addListener(infowindow, 'domready', function () {
          var iwOuter = $('.gm-style-iw')
          iwOuter.addClass('wrap-info-window')
          var iwTc = $('.gm-style-iw-tc')
          iwTc.addClass('wiw')
        })
        infowindow.open({
          anchor: marker,
          map,
        })
        const input = document.getElementById('pac-input') as HTMLInputElement
        console.log('input', input)
        const searchBox = new google.maps.places.SearchBox(input)
        // map.controls[google.maps.ControlPosition.TOP_CENTER].push(input)

        map.addListener('bounds_changed', () => {
          searchBox.setBounds(map.getBounds())
        })

        searchBox.addListener("places_changed", () => {
          const places = searchBox.getPlaces() as any
          console.log('places', places)

          if (places.length == 0) {
            return;
          }

          // Clear out the old markers.
          // marker.forEach((mark: any) => {
          //   mark.setMap(null);
          // });
          marker.setMap(null);

          const bounds = new google.maps.LatLngBounds();

          places.forEach((place: any) => {
            if (!place.geometry || !place.geometry.location) {
              console.log("Returned place contains no geometry");
              return;
            }
            infowindow.close()
            geocodeLatLngAndMarkPan(place, geocoder, infowindow)
            if (place.geometry.viewport) {
              // Only geocodes have viewport.
              bounds.union(place.geometry.viewport);
            } else {
              bounds.extend(place.geometry.location);
            }
          })
          map.fitBounds(bounds)
        })
      })
    }

    onMounted(() => {
      console.log('testx', [existLat.value, existLng.value])
      if (existLng.value && existLat.value) {
        currPos.value.lat = Number(existLat.value)
        currPos.value.lng = Number(existLng.value)
        initMap()
      } else {
        console.log('bbb')
        $getLocation().then(async (res: any) => {
          console.log('res', res)
          currPos.value.lat = await res.lat
          currPos.value.lng = await res.lng
          initMap()
        })
      }
    })
    return {}
  }
})
