import iconDependency from '@/dependencies/iconDependency'

const leftMenu = () => {
  const iconSvg = iconDependency()
  return [
    {
      name: 'BERANDA',
      items: [
        {
          icon: iconSvg.home,
          label: 'Dashboard',
          route: 'dashboard',
          items: [],
          prohibitedRoles: ['External']
        },
        {
          icon: iconSvg.home,
          label: 'Dashboard Roster',
          route: 'dashboard-roster',
          items: [],
          prohibitedRoles: ['Setoran', 'External', 'Adminkontrol']
        },
        {
          icon: iconSvg.report,
          label: 'Laporan',
          route: 'reports',
          prohibitedRoles: []
        }
      ],
      prohibitedRoles: []
    },
    {
      name: 'PERSIAPAN KEBERANGKATAN',
      items: [
        {
          icon: iconSvg.masterRoute,
          label: 'Master Rute',
          route: 'master-route',
          prohibitedRoles: ['Roster', 'Setoran', 'Adminkontrol']
        },
        {
          icon: iconSvg.masterPrice,
          label: 'Master Harga & Komisi',
          route: 'master-price',
          prohibitedRoles: ['Roster', 'Setoran']
        },
        {
          icon: iconSvg.masterExpenses,
          label: 'Master Pengeluaran',
          route: 'master-fixed-expenses',
          prohibitedRoles: ['Roster', 'Setoran']
        },
        {
          icon: iconSvg.masterTrayek,
          label: 'Master Trayek',
          route: null,
          items: [
            {
              label: 'Trayek Utama',
              route: 'master-trayek-main',
              prohibitedRoles: []
            },
            {
              label: 'Trayek Cadangan',
              route: 'master-trayek-reserve',
              prohibitedRoles: []
            },
            {
              label: 'Trayek Pariwisata',
              route: 'master-trayek-tourist',
              prohibitedRoles: []
            }
          ],
          prohibitedRoles: ['Roster', 'Setoran', 'Adminkontrol']
        },
        {
          icon: iconSvg.plotting,
          label: 'Ploting Kondektur',
          route: 'plotting-conductor',
          prohibitedRoles: ['Setoran', 'Adminkontrol']
        },
        {
          icon: iconSvg.departureSchedule,
          label: 'Roster',
          route: 'roster',
          prohibitedRoles: ['Setoran', 'Adminkontrol']
        },
        {
          icon: iconSvg.airport,
          label: 'Bandara',
          route: null,
          items: [
            {
              label: 'Trayek Bandara',
              route: 'master-trayek-airport',
              prohibitedRoles: ['Roster']
            },
            {
              label: 'Roster Bandara',
              route: 'roster-airport',
              prohibitedRoles: []
            },
          ],
          prohibitedRoles: ['Roster', 'Setoran', 'Adminkontrol']
        }
      ],
      prohibitedRoles: ['Setoran', 'External']
    },
    {
      name: 'TRANSAKSI',
      items: [
        {
          icon: iconSvg.approveDeposit,
          label: 'Approve Setoran',
          route: 'approve-deposits',
          prohibitedRoles: ['Roster', 'Adminkontrol']
        },
        {
          icon: iconSvg.reportVerbal,
          label: 'Laporan Verbal',
          route: 'verbal-report',
          prohibitedRoles: ['Roster', 'Setoran']
        },
        {
          icon: iconSvg.reportVerpal,
          label: 'Verpal',
          route: 'verpal-report',
          prohibitedRoles: ['Adminkontrol', 'Setoran']
        },
        {
          icon: iconSvg.location,
          label: 'Tracking Bus',
          route: 'tracking-bus',
          prohibitedRoles: ['Setoran']
        },
      ],
      prohibitedRoles: ['External']
    },
    {
      name: 'KRU',
      items: [{
        icon: iconSvg.kondektur,
        label: 'Kondektur',
        route: 'conductor-account',
        prohibitedRoles: [],
        items: []
      },
      {
        icon: iconSvg.kontroler,
        label: 'Kontroller',
        route: 'controller-account',
        prohibitedRoles: []
      },
      {
        icon: iconSvg.Driver,
        label: 'Sopir',
        route: 'driver-account',
        prohibitedRoles: []
      },
      {
        icon: iconSvg.Helper,
        label: 'Pembantu',
        route: 'helper-account',
        prohibitedRoles: []
      }],
      prohibitedRoles: ['Roster', 'Setoran', 'Adminkontrol', 'External']
    },
    {
      name: 'MASTER',
      items: [
        {
          icon: iconSvg.masterBank,
          label: 'Master Bank',
          route: 'master-bank',
          prohibitedRoles: []
        },
        {
          icon: iconSvg.masterCredentialBank,
          label: 'Master ID Credential Bank',
          route: 'master-credential-bank',
          prohibitedRoles: []
        },
        {
          icon: iconSvg.Helper,
          label: 'Master Mitra Agen',
          route: 'master-partner-agent',
          prohibitedRoles: []
        },
        {
          icon: iconSvg.masterVehicle,
          label: 'Master Kendaraan',
          route: 'master-vehicle',
          prohibitedRoles: []
        },
        {
          icon: iconSvg.masterCity,
          label: 'Master Kota & Terminal',
          route: 'master-city-terminal',
          prohibitedRoles: []
        },
        {
          icon: iconSvg.masterPassengerType,
          label: 'Master Jenis Penumpang',
          route: 'master-passenger-type',
          prohibitedRoles: []
        },
        {
          icon: iconSvg.masterConstraint,
          label: 'Master Jenis Kendala',
          route: 'master-constraint-type',
          prohibitedRoles: []
        },
        {
          icon: iconSvg.masterClass,
          label: 'Master Kelas',
          route: 'master-class',
          prohibitedRoles: []
        },
      ],
      prohibitedRoles: ['Roster', 'Setoran', 'Adminkontrol', 'External']
    },
    {
      name: 'SETTINGS',
      items: [
        {
          icon: iconSvg.globalSettings,
          label: 'Global Settings',
          route: 'global-settings',
          prohibitedRoles: []
        },
      ],
      prohibitedRoles: ['Roster', 'Setoran', 'Adminkontrol', 'External']
    }
  ]
}

export default leftMenu
