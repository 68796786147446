<template>
  <section class="dropdown-list-menu">
    <div :class="classBody()" @click="toggleClick()">
      <div class="flex items-center" :class="store.state.toggleMinimizeMenu ? '' : 'w-full'" v-if="checkIsCanRedirect()" @click="directRouter(listMenu.route)">
        <div class="icon" :class="store.state.toggleMinimizeMenu ? '' : 'mr-3'" v-html="listMenu.icon"></div>
        <h6 v-if="!store.state.toggleMinimizeMenu">{{ listMenu.label }}</h6>
      </div>
      <div class="flex items-center" v-else>
        <div class="icon" :class="store.state.toggleMinimizeMenu ? '' : 'mr-3'" v-html="listMenu.icon"></div>
        <h6 v-if="!store.state.toggleMinimizeMenu">{{ listMenu.label }}</h6>
      </div>
      <div :class="classIcon()" v-html="$icon.svg.arrowRightwardFillEva" v-if="listMenu?.items?.length > 0"></div>
    </div>
    <div :class="classExpandable()" ref="expandable" v-if="listMenu?.items?.length > 0">
      <ul ref="listItem">
        <div :class="classItemMenu(item)" v-for="(item, idxList) in listMenu.items" :key="'menu-item-' + idxList"
          @click="directRouter(item.route)">
          <Icon :icon="icons.circle" class="circle-icon" :height="store.state.toggleMinimizeMenu ? '15px' : '10px'" :width="store.state.toggleMinimizeMenu ? '15px' : '10px'" />
          <div v-if="!store.state.toggleMinimizeMenu" class="w-full">
            <h6 class="pl-2">{{ item.label }}</h6>
          </div>
        </div>
      </ul>
    </div>
  </section>
</template>

<script>
import {
  ref, onMounted
} from 'vue'
import {
  Icon
} from '@iconify/vue'

import {
  useRouter,
  useRoute
} from 'vue-router'
import { useStore } from 'vuex'
import circleFill from '@iconify-icons/akar-icons/circle-fill'

export default {
  name: 'DropdownListMenu',
  props: {
    listMenu: {
      type: Object
    },
    routeName: {
      type: String,
      default: () => ''
    }
  },
  emits: ['checkProhibitedRoles'],
  setup(props, { emit }) {
    const active = ref(false)
    const icons = ref({
      circle: circleFill
    })
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const listMenu = ref(props.listMenu)
    const expandable = ref()
    const listItem = ref()

    const domShowList = () => {
      active.value = !active.value

      if (active.value) {
        expandable.value.style.height = `${listItem.value.clientHeight + 20}px`
      } else {
        expandable.value.style.height = 0
      }
    }
    const directRouter = (val = null) => {
      if (val !== null) {
        // return {
        //   name: val
        // }
        try {
          router.push({
            name: val
          })
          if (store.state.device.width < 1024) {
            store.dispatch('setToggleMenu', false)
          }
        } catch (error) {
          console.log('erorr val !== null', error)
        }
      }
      if (listMenu.value.route !== null && val === null) {
        // return {
        //   name: listMenu.value.route
        // }
        try {
          router.push({
            name: listMenu.value.route
          })
          if (store.state.device.width < 1024) {
            store.dispatch('setToggleMenu', false)
          }
        } catch (error) {
          console.log('ini eror catch', error)
        }
      }
      // return {}
    }
    const checkIsCanRedirect = () => {
      if (listMenu.value.items) {
        return false
      }
      return true
    }
    const toggleClick = () => {
      if (listMenu.value?.items?.length > 0) {
        domShowList()
      } else {
        directRouter()
      }
    }
    const classIcon = () => ((active.value) ? 'arrow ml-auto rotate' : 'arrow ml-auto')

    const classExpandable = () => ((active.value) ? 'expandable active' : 'expandable')

    const classBody = () => {
      // if (route.name.includes(listMenu.value.route)) {
      if (route.name === listMenu.value.route) {
        if (store.state.toggleMinimizeMenu) {
          return 'py-3 px-3 flex items-center justify-center cursor-pointer body active'
        }
        return 'py-3 px-3 flex items-center cursor-pointer body active'
        // } else if (route.name.includes(listMenu.value.route)) {
      // eslint-disable-next-line no-else-return
      } else if (route.name === listMenu.value.route) {
        if (store.state.toggleMinimizeMenu) {
          return 'py-3 px-3 flex items-center justify-center cursor-pointer body active'
        }
        return 'py-3 px-3 flex items-center cursor-pointer body active'
        // } else if (listMenu.value.items.length > 0) {
        //   for (let i = 0; i < listMenu.value.items.length; i++) {
        //     const item = listMenu.value.items[i]
        //     if (route.name.includes(item.route)) {
        //       return 'py-3 px-8 flex items-center cursor-pointer body active'
        //     }
        //   }
      }
      if (store.state.toggleMinimizeMenu) {
        return 'py-3 px-3 flex items-center justify-center cursor-pointer body'
      }
      return 'py-3 px-3 flex items-center cursor-pointer body'
    }

    const classItemMenu = (val) => {
      if (route.name === val.route) {
        if (store.state.toggleMinimizeMenu) {
          return 'px-3 py-3 cursor-pointer item-menu flex items-center active'
        }
        return 'px-8 py-3 cursor-pointer item-menu flex items-center active'
        // eslint-disable-next-line no-else-return
      } else if (route.name === listMenu.value.route) {
      // } else if (route.name.includes(val.route)) {
        if (store.state.toggleMinimizeMenu) {
          return 'px-3 py-3 cursor-pointer item-menu flex items-center active'
        }
        return 'px-8 py-3 cursor-pointer item-menu flex items-center active'
      }
      if (store.state.toggleMinimizeMenu) {
        return 'px-3 py-3 cursor-pointer item-menu flex items-center'
      }
      return 'px-8 py-3 cursor-pointer item-menu flex items-center'
    }

    return {
      active,
      icons,
      expandable,
      listItem,
      classItemMenu,
      classBody,
      classExpandable,
      classIcon,
      directRouter,
      toggleClick,
      checkIsCanRedirect,
      store
    }
  },
  components: {
    Icon
  }
}
</script>
