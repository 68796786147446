
import InputNumber from 'primevue/inputnumber'
import InputText from 'primevue/inputtext'
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import {
  getCurrentInstance,
  onMounted,
  ref,
  toRefs
} from 'vue'
import apiUseCase from '@/usecase/apiUseCase'
import { useStore } from 'vuex'
import { isNull } from 'lodash'
import Checkbox from 'primevue/checkbox'

export default {
  components: {
    Dialog,
    // Form,
    // Field,
    Checkbox,
    Button
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    ritData: {
      default: null as any
    },
  },
  emits: ['hideScheduleDialog'],
  setup(props: any, { emit }: any) {
    const app = getCurrentInstance()
    const {
      $toast
    } = app!.appContext.config.globalProperties
    const store = useStore()
    const isLoading = ref(true)
    const { show, ritData } = toRefs(props)
    const showModal = show.value
    const dataSource = ref([]) as any
    const dataSourceTemp = ref([]) as any
    const dataLatestRevision = ref() as any
    const ritCount = ref() as any
    const isCrossdayValue = ref(false)

    const endpoint = '/roster/v2/Rosters'

    // const getLatestRevision = () => {
    //   apiUseCase.get(`${endpointExpenseRevision}/Latest-Revision/${ritId.value}`).then((response) => {
    //     dataLatestRevision.value = response.result.Items
    //     if (dataLatestRevision.value.length > 0) {
    //       for (let index = 0; index < dataSourceTemp.value.length; index++) {
    //         const temp = {
    //           ritExpenseId: dataSourceTemp.value[dataSourceTemp.value.length - (index + 1)].ritExpenseId,
    //           type: dataSourceTemp.value[dataSourceTemp.value.length - (index + 1)].type,
    //           nominal: dataLatestRevision.value[index].RevisiAmount
    //         }
    //         dataSource.value.push(temp)
    //       }
    //     } else {
    //       dataSource.value = dataSourceTemp.value
    //     }
    //   })
    // }

    const getDetail = () => {
      store.dispatch('showLoading')
      dataSourceTemp.value = []
      apiUseCase.get(`${endpoint}/${ritData.value.rosterId}/RosterDetails/${ritData.value.Id}`).then((response) => {
        if (!response.error) {
          console.log('response', response)
          // eslint-disable-next-line array-callback-return, no-unused-expressions
          response.result.Routes.map((data: any) => {
            const obj = {
              terminalId: data.StationId,
              name: data.StationName,
              cityName: data.CityName,
              departure: data.DepartureTime ? data.DepartureTime.slice(0, 5) : null,
              arrival: data.ArrivalTime ? data.ArrivalTime.slice(0, 5) : null,
              routeDetailId: data.Id
            }
            dataSourceTemp.value.push(obj)
          })
          console.log('dataSource', dataSourceTemp.value)
        }
        ritCount.value = response.result.RitOrder
        isCrossdayValue.value = response.result.IsCrossDay
      })
      dataSource.value = dataSourceTemp.value
      isLoading.value = false
      store.dispatch('hideLoading')
    }

    const closeDialog = () => {
      emit('hideScheduleForm')
    }

    // const onSubmitConfirmation = async (val: any = '') => {
    //   // const deletedId = val === '' ? dataRow.value.Id : val
    //   const {
    //     error,
    //     message
    //   } = await apiUseCase.postOrPut(`${endpointExpenseRevision}`, null, dataForm.value)
    //   if (error) {
    //     $toast.add({
    //       severity: 'error',
    //       // summary: '',
    //       detail: message,
    //       group: 'bc',
    //       closable: false,
    //       life: 3000
    //     })
    //   } else {
    //     $toast.add({
    //       severity: 'success',
    //       // summary: 'Berhasil melakukan Approve Setoran',
    //       detail: message,
    //       group: 'bc',
    //       closable: false,
    //       life: 3000
    //     })
    //     store.dispatch('setReloadDataList')
    //     emit('reloadData')
    //     closeDialog()
    //   }
    //   store.dispatch('hideLoading')
    // }

    const onSubmit = () => {
      store.dispatch('showLoading')
      const obj = {
        data: dataSource.value,
        // ...ritData.value
        busOrder: ritData.value.busOrder,
        ppOrder: ritData.value.ppOrder,
        ritOrder: ritData.value.ritOrder,
        isCrossDay: isCrossdayValue.value
      }
      emit('onSubmit', obj)
      store.dispatch('hideLoading')
      // eslint-disable-next-line array-callback-return
      // dataSource.value.map((x: any) => {
      //   if (isNull(x.nominal)) {
      //     x.nominal = 0
      //   }
      //   const formTemp = {
      //     ritExpenseId: x.ritExpenseId,
      //     ritId: ritId.value,
      //     nominal: x.nominal,
      //     verbatim: null
      //   }
      //   dataForm.value.push(formTemp)
      // })

      // onSubmitConfirmation()
    }

    onMounted(() => {
      console.log('rit', ritData.value)
      getDetail()
    })

    return {
      isLoading,
      showModal,
      closeDialog,
      dataSource,
      onSubmit,
      isCrossdayValue,
      ritCount
    }
  }
}
