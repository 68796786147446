
import { defineComponent, onMounted, ref, watch } from 'vue'
import Card from 'primevue/card'
import apiUseCase from '@/usecase/apiUseCase'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'VerbalDetail',
  components: {
    Card
  },
  setup() {
    const rit = ref(2)
    const store = useStore()
    // const endpoint = 'transaction/v1/Dashboard/PendapatanPerBusBerjalan'
    const endpoint = 'transaction/v1/Dashboard/BusDashboardIncome'
    const dataSource = ref([]) as any
    const isLoading = ref(true)

    const getRit2 = async () => {
      store.dispatch('showLoading')
      await apiUseCase.get(`${endpoint}?pageNumber=1`).then((response) => {
        // console.log('response', response.result)
        dataSource.value = response.result.filter((data: any) => data.RitOrder === 2)
        console.log('dataSource', dataSource.value)
        isLoading.value = false
      })
      store.dispatch('hideLoading')
    }

    const currencyNumberFormat = (val: any) => `Rp. ${Number(val).toLocaleString('id-ID')}`

    watch(() => store.getters.getReloadDashboardRit2Table, (val) => {
      if (val) {
        console.log('2222')
        // isLoading.value = true
        getRit2()
      }
    })

    onMounted(() => {
      getRit2()
    })
    return {
      isLoading,
      dataSource,
      currencyNumberFormat
    }
  }
})
