import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { IApprovePpState } from './model'
import { IRootState } from '@/store/model'

const states: IApprovePpState = {
  id: '',
  isReload: false,
  selectedStatus: 0
}

const mutations: MutationTree<IApprovePpState> = {
  SET_RELOAD_APPROVE_PP: (state, isReload: boolean, id = '') => {
    state.id = id
    state.isReload = isReload
    if (isReload) {
      setTimeout(() => {
        state.id = ''
        state.isReload = !isReload
      }, 2000)
    }
  },
  SET_SELECTED_STATUS: (state, data: number | null) => {
    state.selectedStatus = data
  }
}

const actions: ActionTree<IApprovePpState, IRootState> = {
  setReloadApprovePp: async ({ commit }, id) => {
    commit('SET_RELOAD_APPROVE_PP', true, id)
  },
  setSelectedStatus: async ({ commit }, data) => {
    commit('SET_SELECTED_STATUS', data)
  }
}

const getters: GetterTree<IApprovePpState, IRootState> = {
  getReloadApprovePp(state) {
    return state.isReload
  },
  getSelectedStatus(state) {
    return state.selectedStatus
  },
}

export default {
  namespaced: true,
  state: states,
  mutations,
  actions,
  getters
}
