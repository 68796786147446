
import Checkbox from 'primevue/checkbox'
import { computed, getCurrentInstance, onMounted, ref, toRefs } from 'vue'
import { useStore } from 'vuex'
import Button from 'primevue/button'
import apiUseCase from '@/usecase/apiUseCase'
import returnUrlPrams from '@/utils/helpers/returnUrlPrams'

export default {
  props: {
    type: {
      default: () => 0,
    },
  },

  components: {
    Checkbox,
    Button
  },

  setup(props: any) {
    const { type } = toRefs(props)
    const selectedPassenger = ref([]) as any
    const selectedPassengerTemp = ref([]) as any
    const store = useStore()
    const app = getCurrentInstance()
    const {
      $toast, $strInd
    } = app!.appContext.config.globalProperties

    const passengerEndpoint = '/management/v1/MsPassenger'
    const detailPriceEndpoint = '/management/v1/MsDetilPrice'

    const dataOptions = ref([]) as any
    const priceData = ref(store.state.hjpTable.modal.data)
    const passengerId = ref(store.state.selectedPassenger)
    const priceChangeId = ref(store.state.selectedPriceChange)
    const dataTemp = ref([]) as any
    const isLoading = ref(true)
    const dataForm = ref(null) as any
    const filters = {
      pageNumber: 1
    }

    const showModalForm = computed({
      get: () => store.state.hjpDataList.modalForm.show,
      set: (value) => {
        store.dispatch('setShowModalFormDl', value)
      }
    })

    const hideModalForm = () => {
      showModalForm.value = false
    }

    const onClickBtnAdd = (val: any) => {
      console.log('onClickBtnAdd', val)
      // store.dispatch('flushRowDataTable')
      showModalForm.value = true
    }

    const getAllDataPassenger = () => {
      apiUseCase.get(`${passengerEndpoint}${returnUrlPrams({
        pageNumber: filters.pageNumber
      })}`).then((response) => {
        // eslint-disable-next-line array-callback-return
        response.result.map((x: any) => {
          const obj = {
            Type: x.Type,
            Id: x.Id
          }
          dataOptions.value.push(obj)
        })
        // dataOptions.value.push(dataOptionsTemp)
        if (dataOptions.value.length < response.count) {
          filters.pageNumber += 1
          getAllDataPassenger()
        } else {
          // eslint-disable-next-line no-use-before-define
          getCurrentPassengerType()
        }
      })
      console.log('data', dataOptions)
    }

    const prosesSubmit = async (data: any) => {
      const toastMessage = 'Pelanggan berhasil ditambahkan!'
      const response = await apiUseCase.postOrPut(`${detailPriceEndpoint}/Multiple`, null, data)
      console.log('response', response)
      if (!response.error) {
        store.dispatch('setShowModal', false)
        store.dispatch('setReloadDataList', true)
        store.dispatch('setSelectedPassenger', data.msPassengerId[0])
        $toast.add({
          severity: 'success',
          detail: toastMessage,
          group: 'bc',
          closable: false,
          life: 3000
        })
        hideModalForm()
        // store.dispatch('setReloadDataTable')
      } else {
        $toast.add({
          severity: 'error',
          detail: response.message,
          group: 'bc',
          closable: false,
          life: 3000
        })
      }
    }

    const prosesDuplicate = async (data: any) => {
      console.log('dataDuplicate', data)
      const toastMessage = 'Pelanggan berhasil diduplikat!'
      const response = await apiUseCase.postOrPut(`${detailPriceEndpoint}/Duplicate`, null, data)
      console.log('response', response)
      if (!response.error) {
        store.dispatch('setShowModal', false)
        store.dispatch('setReloadDataList', true)
        store.dispatch('setSelectedPassenger', data.msPassengerId[0])
        $toast.add({
          severity: 'success',
          detail: toastMessage,
          group: 'bc',
          closable: false,
          life: 3000
        })
        hideModalForm()
        // store.dispatch('setReloadDataTable')
      } else {
        $toast.add({
          severity: 'error',
          detail: response.message,
          group: 'bc',
          closable: false,
          life: 3000
        })
      }
    }

    const submitData = () => {
      store.dispatch('showLoading')
      console.log('selected', selectedPassenger.value)
      const dataPassengerTemp = [] as any
      const filteredPassenger = selectedPassenger.value.filter((x: any) => !selectedPassengerTemp.value.some((y: any) => y === x))
      console.log('filtered', filteredPassenger)
      // eslint-disable-next-line array-callback-return
      filteredPassenger.map((x: any) => {
        dataPassengerTemp.push(x)
      })

      if (type.value === 3) {
        dataForm.value = {
          // msPriceId: priceData.value.Id,
          // msDetailPriceId: priceChangeId.value,
          msPriceId: priceChangeId.value,
          duplicateId: passengerId.value,
          msPassengerId: dataPassengerTemp
        }

        prosesDuplicate(dataForm.value)
      } else {
        dataForm.value = {
          msPriceId: priceChangeId.value,
          msPassengerId: dataPassengerTemp
        }
        console.log('dataform', dataForm.value)
        // console.log('store', store.state)
        prosesSubmit(dataForm.value)
      }
      store.dispatch('hideLoading')
    }

    const getCurrentPassengerType = async () => {
      await apiUseCase.get(`${detailPriceEndpoint}/passangers/${priceChangeId.value}`).then((response) => {
        console.log('passenger', response)
        // eslint-disable-next-line array-callback-return
        response.result.map((x: any) => {
          dataTemp.value.push(x.IdPassenger)
        })
      })
      selectedPassenger.value = dataTemp.value
      selectedPassengerTemp.value = selectedPassenger.value
      isLoading.value = false
    }

    onMounted(() => {
      console.log('formType', type)
      const title = type.value === 3 ? 'Duplikat' : 'Tambah'
      store.dispatch('setTitleFormSidebar', `${title} Jenis Penumpang`)
      getAllDataPassenger()
      console.log('store', store.state)
      isLoading.value = true
    })

    return {
      selectedPassenger,
      selectedPassengerTemp,
      showModalForm,
      hideModalForm,
      onClickBtnAdd,
      dataOptions,
      submitData,
      isLoading,
    }
  }
}
