
import InputText from 'primevue/inputtext'
import { computed, getCurrentInstance, onMounted, ref, toRefs, watch } from 'vue'
import StatusApprovalOptions from '@/data/local/json/statusApprovalOptions'

export default {
  components: {
    InputText,
  },
  props: {
    selectedStatusId: {
      default: () => 0
    }
  },
  emits: ['showDropDown', 'onSearch'],
  setup(props: any, { emit } : any) {
    const placeholder = ref('Cari Disini...')
    const inputSearch = ref('')
    const canClick = ref(true)
    const {
      selectedStatusId
    } = toRefs(props)
    const statusOptions = ref(StatusApprovalOptions())
    const selectedStatusLabel = ref('')
    const showDropDown = (evt: any, val: any) => {
      console.log('xxx')
      emit('showDropDown', evt, val)
    }

    const onSearch = () => {
      console.log('onSearch', inputSearch.value)
      emit('onSearch', inputSearch.value)
    }

    const getStatusLabel = () => {
      selectedStatusLabel.value = statusOptions.value.filter((x: any) => x.val === selectedStatusId.value)[0].name
    }

    onMounted(() => {
      getStatusLabel()
    })

    watch(selectedStatusId, (val: any) => {
      getStatusLabel()
    })

    return {
      placeholder,
      inputSearch,
      canClick,
      showDropDown,
      selectedStatusId,
      selectedStatusLabel,
      onSearch
    }
  }
}

