
import { defineComponent, getCurrentInstance, onMounted, ref, reactive, computed, watch, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import apiUseCase from '@/usecase/apiUseCase'
import { ITrayek } from '@/utils/interfaces/iTrayek'
import returnUrlPrams from '@/utils/helpers/returnUrlPrams'
import moment from 'moment'
import { isEmpty } from 'lodash'
import Card from 'primevue/card'
import IResponse from '@/utils/interfaces/iResponse'
import InputIconRight from '@/views/components/form/InputIconRight.vue'
import PariwisataRosterDialog from '@/views/pages/roster/components/DatePickerPariwisataDialog.vue'
import { PageState } from 'primevue/paginator'
import Paginator from '@/views/components/paginator/Paginator.vue'
import HeaderFilter from '@/views/pages/plotting-conductor/components/HeaderFilter.vue'
import LeftFilter from '@/views/pages/plotting-conductor/components/LeftFilter.vue'
import TablePlotting from '@/views/pages/plotting-conductor/components/TablePlotting.vue'
import InfoConductor from '@/views/pages/plotting-conductor/components/InfoConductor.vue'
import HjpSideBar from '@/views/components/sidebar/HjpSideBar.vue'
import ChangeStatusForm from '@/views/pages/roster/form/ChangeStatusForm.vue'
import ChangeVehicleForm from '@/views/pages/roster/form/ChangeBusForm.vue'
import ChangeStatusActiveForm from '@/views/pages/roster/form/ChangeStatusActiveForm.vue'
import HjpCofirmModal from '@/views/components/modal/HjpConfirmationModal.vue'
import { IPlotting } from '@/utils/interfaces/iPlotting'
import { IPreviewRoster } from '@/utils/interfaces/iRoster'
import { IFilter } from '@/store/modules/plotting/model'

const module = 'plotting'

export default defineComponent({
  name: 'Roster',
  components: {
    Card,
    InputIconRight,
    HeaderFilter,
    LeftFilter,
    TablePlotting,
    Paginator,
    PariwisataRosterDialog,
    HjpSideBar,
    ChangeStatusForm,
    HjpCofirmModal,
    InfoConductor,
    ChangeVehicleForm,
    ChangeStatusActiveForm
  },
  setup() {
    const moduleStore = 'hjpTable'
    const moduleRoster = 'roster'
    const app = getCurrentInstance()
    const {
      $icon,
      $toast
    } = app!.appContext.config.globalProperties
    const icon = $icon
    const store = useStore()
    // const endpointPlotting = '/roster/v1/Plotting'
    const endpointTrayek = '/management/v1/Trayek'
    const endpointRoster = '/roster/v1/Roster'
    const endpointRoster2 = '/roster/v2/Rosters'
    const selectedConductor = computed(() => store.getters[`${module}/selectedConductor`])
    const plottingList = ref()
    const selectedTrayek = ref('')
    const placeholder = ref('Cari disini ...')
    const inputSearch = ref('')
    const filterDate = ref<Date>(moment().toDate())
    const trayekList = ref<ITrayek[]>([])
    const dataForm = ref()
    const dataFormEdit = ref()
    const lastUpdate = ref(moment())
    const isEdit = ref(false)
    const isSearch = ref(false)
    const sidebarFormState = ref(0)
    const currentKondekturRoster = ref([]) as any
    const currentSelectedKondektur = ref(null)
    const modalConfirmation = reactive({
      show: false,
      imgContent: require('@/assets/img/modal-confirmation/roster-confirmation.svg'),
      title: '',
      subTitle: '',
      btnSubmitTitle: 'Simpan',
      subTitleHtml: false,
      isDelete: false
    })
    const headerButton = ref({
      isShow: true,
      label: 'Tambah Pariwisata',
    })
    const showModalForm = computed({
      get: () => store.state[moduleStore].modal.show,
      set: (value) => {
        store.dispatch('setShowModal', value)
      }
    })

    const plottingTableProp = reactive({
      totalRecords: 0,
      isLoading: false,
      pageNumber: 1
    })

    const hideConfirmDialog = () => {
      modalConfirmation.show = false
      isEdit.value = false
    }

    const processGetPlottingDetail = async () => {
      try {
        const dateFilter = moment(filterDate.value.toDateString()).format('YYYY-MM-DD')
        console.log('dateFilter', dateFilter)
        const {
          result,
          error,
          count,
          pagenumber
        } = await apiUseCase.get(`${endpointRoster2}/ByTrayek/${selectedTrayek.value}${returnUrlPrams({
          pageNumber: plottingTableProp.pageNumber,
          filterField: ['regNumber'],
          search: inputSearch.value,
          pageSize: 10,
          custom: [['RosterDate', '=', dateFilter], ['statusRosterCode', '=', 'GBG'], ['statusRosterCode', '=', 'RGL']]
        })}`)
        if (!error) {
          const data = result as IPlotting[]
          plottingList.value = data.map((v) => ({
            ...v,
            Pp: v.PpOrder,
            DepartureTime: v.DepartureTime
          }))
          plottingTableProp.totalRecords = count
          plottingTableProp.pageNumber = pagenumber
        }
        isEdit.value = false
        isSearch.value = false
        plottingTableProp.isLoading = false
      } catch (error) {
        isEdit.value = false
        isSearch.value = false
        plottingTableProp.isLoading = false
      }
    }

    const getPlottingDetail = async (id: string) => {
      // store.dispatch('showLoading')
      plottingTableProp.isLoading = true
      if (id === selectedTrayek.value && !isEdit.value && !isSearch.value) {
        selectedTrayek.value = ''
        plottingTableProp.isLoading = false
        plottingTableProp.pageNumber = 1
        store.dispatch('hideLoading')
      } else {
        if (id !== selectedTrayek.value && !isEdit.value && !isSearch.value) {
          plottingTableProp.isLoading = false
          plottingTableProp.pageNumber = 1
        }
        selectedTrayek.value = id
        processGetPlottingDetail()
      }
    }

    watch(filterDate, (newVal) => {
      // eslint-disable-next-line no-use-before-define
      getPreviewRoster()
      // eslint-disable-next-line no-use-before-define
      refreshPage()
    })

    const paginate = reactive({
      pageSize: 10,
      pageNumber: 1,
      totalRecords: 0,
    })

    const filtersTrayek = reactive({
      custom: [] as any
    })
    const getAllTrayek = async () => {
      store.dispatch('showLoading')
      filtersTrayek.custom.push(['activeOnly', '=', 'true'], ['trayekTypeId', '=', '8e072a19-599c-45f0-a337-08dbd378cce6'])
      const response = await apiUseCase.get(`${endpointTrayek}${returnUrlPrams({
        pageSize: paginate.pageSize,
        pageNumber: paginate.pageNumber,
        custom: filtersTrayek.custom
      })}`)
      if (!response.error) {
        const result = response.result as ITrayek[]
        trayekList.value = result
        paginate.totalRecords = response.count
      }
      store.dispatch('hideLoading')
    }
    const listFilterTrayek = computed<IFilter[]>(() => store.getters[`${module}/filterTrayek`])
    watch(listFilterTrayek.value, (newVal) => {
      const mapResult: string[][] = newVal.map((v: IFilter) => ['name', '=', v.label])
      const findFilter: string[] = filtersTrayek.custom.filter((v: string[]) => v[0] !== 'name')
      filtersTrayek.custom = [...findFilter, ...mapResult]
      getAllTrayek()
    })

    const listFilterClass = computed<IFilter[]>({
      get: () => store.getters[`${module}/filterClass`],
      set: (val) => {
        console.log('val changers', val)
      }
    })
    watch(listFilterClass.value, (newVal) => {
      const mapResult: string[][] = newVal.map((v: IFilter) => ['classId', '=', v.value])
      const findFilter: string[] = filtersTrayek.custom.filter((v: string[]) => v[0] !== 'classId')
      filtersTrayek.custom = [...findFilter, ...mapResult]
      getAllTrayek()
    })
    const listFilterTol = computed<IFilter[]>(() => store.getters[`${module}/filterTol`])
    watch(listFilterTol.value, (newVal) => {
      const mapResult: string[][] = newVal.map((v: IFilter) => ['isTol', '=', v.value])
      const findFilter: string[] = filtersTrayek.custom.filter((v: string[]) => v[0] !== 'isTol')
      filtersTrayek.custom = [...findFilter, ...mapResult]
      getAllTrayek()
    })

    const getDetailRosterPariwisata = async () => {
      store.dispatch('showLoading')
      console.log('isEdit', isEdit.value)
      plottingTableProp.isLoading = true
      if (selectedTrayek.value === 'pariwisata' && !isEdit.value && !isSearch.value) {
        console.log('pariwisata')
        selectedTrayek.value = ''
        plottingTableProp.isLoading = false
        plottingTableProp.pageNumber = 1
        store.dispatch('hideLoading')
      } else {
        try {
          selectedTrayek.value = 'pariwisata'
          // selectedTrayek.value = id
          const dateFilter = moment(filterDate.value.toDateString()).format('YYYY-MM-DD')
          console.log('dateFilter', dateFilter)
          const {
            result,
            error,
            count,
            pagenumber
          } = await apiUseCase.get(`${endpointRoster}${returnUrlPrams({
            pageNumber: plottingTableProp.pageNumber,
            filterField: ['regNumber'],
            search: inputSearch.value,
            pageSize: 10,
            custom: [['startDate', '=', dateFilter], ['endDate', '=', dateFilter], ['statusRosterCode', '=', 'PRW']]
          })}`)
          if (!error) {
            const data = result as IPlotting[]
            plottingList.value = data.map((v) => ({
              ...v,
              Pp: v.PpOrder,
              DepartureTime: v.DepartureTime
            }))
            plottingTableProp.totalRecords = count
            plottingTableProp.pageNumber = pagenumber
          }
          console.log('error pariwisata1')
          plottingTableProp.isLoading = false
          store.dispatch('hideLoading')
          isEdit.value = false
          isSearch.value = false
        } catch (error) {
          console.log('error pariwisata2')
          plottingTableProp.isLoading = false
          store.dispatch('hideLoading')
          isEdit.value = false
          isSearch.value = false
        }
      }
    }

    const progressSubmitPlotting = async () => {
      console.log('xxxx')
      store.dispatch('showLoading')
      try {
        const {
          error,
          message
        } = await apiUseCase.put(`${endpointRoster}/${dataFormEdit.value.Id}`, dataFormEdit.value)
        if (!error) {
          $toast.add({
            severity: 'success',
            detail: message,
            group: 'bc',
            closable: false,
            life: 3000
          })
        } else {
          $toast.add({
            severity: 'error',
            detail: message,
            group: 'bc',
            closable: false,
            life: 3000
          })
        }
        if (selectedTrayek.value === 'pariwisata') {
          console.log('error pariwisata4')
          getDetailRosterPariwisata()
        } else {
          getPlottingDetail(selectedTrayek.value)
        }
        hideConfirmDialog()
        store.dispatch('hideLoading')
      } catch (error) {
        console.log('error pariwisata3')
        if (selectedTrayek.value === 'pariwisata') {
          getDetailRosterPariwisata()
        } else {
          getPlottingDetail(selectedTrayek.value)
        }
        hideConfirmDialog()
        // isEdit.value = false
        store.dispatch('hideLoading')
      }
    }

    const mappingCurrentCondecturRoster = (data: any) => {
      let temp: any = null
      let currIndex: any = -1
      currentKondekturRoster.value = []
      for (let index = 0; index < data.length; index++) {
        if (data[index].RegNumber !== temp) {
          temp = data[index].RegNumber
          currIndex += 1
          const obj = {
            nopol: data[index].RegNumber,
            pps: [] as any
          }
          obj.pps.push({ order: data[index].PpOrder, time: data[index].DepartureTime.slice(0, 5) })
          currentKondekturRoster.value[currIndex] = obj
        } else {
          currentKondekturRoster.value[currIndex].pps.push({ order: data[index].PpOrder, time: data[index].DepartureTime.slice(0, 5) })
        }
      }
    }

    const onSubmitPlotting = async (val: any) => {
      console.log('val', val)
      isEdit.value = true
      const dateFilter = moment(filterDate.value.toDateString()).format('YYYY-MM-DD')
      console.log('submit', val)
      val.RosterDate = dateFilter
      dataFormEdit.value = val
      try {
        const { error, message, result } = await apiUseCase.get(`${endpointRoster}/Condectur/${val.ActiveCondecturId}${returnUrlPrams({
          custom: [['rosterDate', '=', dateFilter]]
        })}`)
        if (!error) {
          if (result.CondecturRosterCount > 0) {
            mappingCurrentCondecturRoster(result.CondecturRosters)
            modalConfirmation.title = `Kondektur ${result.CondecturName} Sudah Mempunyai Jadwal`
            modalConfirmation.subTitle = 'Kondektur sudah mempunyai jadwal, mohon cek jadwal kondektur berikut ini : '
            modalConfirmation.btnSubmitTitle = 'Tetap Simpan Roster'
            modalConfirmation.imgContent = require('@/assets/img/modal-confirmation/dirty-edit-plotting.svg')
          } else {
            modalConfirmation.title = 'Apakah Anda Yakin Ingin Menyimpan Roster?'
            modalConfirmation.subTitle = 'Pastikan data yang Anda masukkan  sudah benar sebelum tersimpan'
            modalConfirmation.btnSubmitTitle = 'Simpan'
            modalConfirmation.imgContent = require('@/assets/img/modal-confirmation/clean-edit-plotting.svg')
          }
          modalConfirmation.show = true
        } else {
          $toast.add({
            severity: 'error',
            detail: message,
            group: 'bc',
            closable: false,
            life: 3000
          })
          if (selectedTrayek.value === 'pariwisata') {
            getDetailRosterPariwisata()
          } else {
            getPlottingDetail(selectedTrayek.value)
          }
        }
        store.dispatch('hideLoading')
      } catch (error) {
        if (selectedTrayek.value === 'pariwisata') {
          getDetailRosterPariwisata()
        } else {
          getPlottingDetail(selectedTrayek.value)
        }
        store.dispatch('hideLoading')
      }
    }

    const onSearch = () => {
      // console.log('valSearch', val)
      isSearch.value = true
      plottingTableProp.pageNumber = 1
      if (selectedTrayek.value === 'pariwisata') {
        getDetailRosterPariwisata()
      } else {
        getPlottingDetail(selectedTrayek.value)
      }
    }

    const onChangePage = ({ page }: any) => {
      paginate.pageNumber = page + 1
      getAllTrayek()
    }

    const onChangeStatusRosterAction = () => {
      console.log('onChangeStatusRosterAction', store.state)
      sidebarFormState.value = 0
      showModalForm.value = true
    }

    const onChangeVehicleRosterAction = () => {
      console.log('onChangeStatusRosterAction', store.state)
      sidebarFormState.value = 1
      showModalForm.value = true
    }

    const onChangeStatusActiveRosterAction = () => {
      sidebarFormState.value = 2
      showModalForm.value = true
    }

    const hideModalForm = () => {
      showModalForm.value = false
    }

    const showConfirmDialog = (data: any, isChangeStatus: any) => {
      console.log('data', data)
      isEdit.value = false
      dataForm.value = null
      dataForm.value = data
      if (isChangeStatus) {
        modalConfirmation.isDelete = false
        modalConfirmation.subTitleHtml = true
        modalConfirmation.title = 'Ganti Status?'
        modalConfirmation.subTitle = `<div class="text-grey">Apakah Anda yakin akan mengganti status <span class="font-bold" style="color: red; text-decoration: line-through;">${store.state.hjpTable.modal.data.RosterStatusName}</span> pada bus <span style="color: black;">${store.state.hjpTable.modal.data.RegNumber}, PP${store.state.hjpTable.modal.data.Pp} (${store.state.hjpTable.modal.data.DepartureTime.slice(0, 5)})</span> menjadi <span class="font-bold text-primary">${dataForm.value.Status.Name ?? store.state.hjpTable.modal.data.MsStatusRoster?.Name}</span>?</div>`
        modalConfirmation.btnSubmitTitle = 'Ganti Status'
      } else {
        modalConfirmation.imgContent = require('@/assets/img/modal-confirmation/vehicle-add-update.svg')
        modalConfirmation.isDelete = true
        modalConfirmation.subTitleHtml = true
        modalConfirmation.title = dataForm.value.IsActive ? 'Non Aktifkan Bus ?' : 'Aktifkan Bus ?'
        modalConfirmation.subTitle = dataForm.value.IsActive
          ? `<div class="text-grey">Bus <span class="font-bold">${data.RegNumber}</span> akan di non aktifkan. Bus tidak akan dapat diberi jadwal hingga diaktifkan kembali</div>`
          : `<div class="text-grey">Bus <span class="font-bold">${data.RegNumber}</span> akan diaktifkan kembali.</div>`
        modalConfirmation.btnSubmitTitle = dataForm.value.IsActive ? 'Nonaktifkan Bus' : 'Aktifkan Bus'
      }
      modalConfirmation.show = true
    }

    const onDeactiveRosterAction = (data: any) => {
      console.log('onDeactiveRosterAction', store.state)
      if (!store.state.hjpTable.modal.data.IsActive) {
        showConfirmDialog(store.state.hjpTable.modal.data, false)
      } else {
        const obj = {
          ...store.state.hjpTable.modal.data,
          Description: data.Description
        }
        showConfirmDialog(obj, false)
      }
    }

    const submitFromDialog = () => {
      console.log('dataForm', dataForm.value)
      if (modalConfirmation.isDelete) {
        const payload = {
          isActived: !dataForm.value.IsActive,
          statusRemark: dataForm.value.Description ?? null
        }
        store.dispatch('showLoading')
        apiUseCase.patch(`${endpointRoster}`, dataForm.value.Id, payload).then((res: IResponse) => {
          if (!res.error) {
            $toast.add({
              severity: 'success',
              detail: res.message,
              group: 'bc',
              closable: false,
              life: 3000
            })
            showModalForm.value = false
            selectedTrayek.value = ''
          } else {
            $toast.add({
              severity: 'error',
              detail: res.message,
              group: 'bc',
              closable: false,
              life: 3000
            })
            showModalForm.value = false
            selectedTrayek.value = ''
          }
        }).catch((err: any) => {
          console.log('err delete', err)
        })
      } else {
        const payload = {
          msStatusRosterId: dataForm.value.Status.Id ?? store.state.hjpTable.modal.data.MsStatusRoster?.Id,
          statusRemark: dataForm.value.Description
        }

        store.dispatch('showLoading')
        apiUseCase.patch(`${endpointRoster}/ChangeStatus`, store.state.hjpTable.modal.data.Id, payload).then((res: IResponse) => {
          if (!res.error) {
            $toast.add({
              severity: 'success',
              detail: res.message,
              group: 'bc',
              closable: false,
              life: 3000
            })
            showModalForm.value = false
            selectedTrayek.value = ''
          } else {
            $toast.add({
              severity: 'error',
              detail: res.message,
              group: 'bc',
              closable: false,
              life: 3000
            })
            showModalForm.value = false
            selectedTrayek.value = ''
          }
        }).catch((err: any) => {
          console.log('err delete', err)
        })
      }
      hideConfirmDialog()
      store.dispatch('hideLoading')
    }

    const rosterPreview = ref<IPreviewRoster>()

    const getPreviewRoster = async () => {
      store.dispatch('showLoading')
      try {
        const dateFilter = moment(filterDate.value.toDateString()).format('YYYY-MM-DD')
        const {
          result,
          error
        } = await apiUseCase.get(`${endpointRoster}/PreviewRoster${returnUrlPrams({
          custom: [['rosterDate', '=', dateFilter]]
        })}`)
        if (!error) {
          const resultMap = result as IPreviewRoster
          rosterPreview.value = resultMap
        }
        store.dispatch('hideLoading')
      } catch (error) {
        store.dispatch('hideLoading')
      }
    }

    const refreshPage = () => {
      showModalForm.value = false
      selectedTrayek.value = ''
      lastUpdate.value = moment()
      getAllTrayek()
    }

    watch(store.getters[`${moduleRoster}/isReload`], (newVal) => {
      // eslint-disable-next-line no-use-before-define
      getPreviewRoster()
      // eslint-disable-next-line no-use-before-define
      refreshPage()
    })

    watch(store.getters[`${moduleRoster}/isReloadDetail`], (newVal) => {
      if (selectedTrayek.value === 'pariwisata') {
        getDetailRosterPariwisata()
      } else {
        getPlottingDetail(selectedTrayek.value)
      }
    })

    const generateRosterAction = () => {
      console.log('asdsad')
      store.dispatch('showLoading')
      const payload = {
        RosterDate: filterDate.value
      }
      apiUseCase.postWithNoId(`${endpointRoster}/GenerateRoster`, payload).then((res: IResponse) => {
        if (!res.error) {
          $toast.add({
            severity: 'success',
            detail: res.message,
            group: 'bc',
            closable: false,
            life: 3000
          })
          showModalForm.value = false
          selectedTrayek.value = ''
          refreshPage()
        } else {
          $toast.add({
            severity: 'error',
            detail: res.message,
            group: 'bc',
            closable: false,
            life: 3000
          })
        }
      }).catch((err: any) => {
        console.log('err delete', err)
      })
      store.dispatch('hideLoading')
    }

    const nextPagePlotting = (evt: PageState) => {
      // console.log('parent page', evt)
      plottingTableProp.pageNumber = evt.page + 1
      isSearch.value = true
      if (selectedTrayek.value === 'pariwisata') {
        getDetailRosterPariwisata()
      } else {
        getPlottingDetail(selectedTrayek.value)
      }
    }

    const reloadFromVehicle = () => {
      processGetPlottingDetail()
    }

    onMounted(() => {
      store.dispatch('setHeaderButton', headerButton.value)
      getPreviewRoster()
      getAllTrayek()
    })

    onBeforeUnmount(() => {
      store.dispatch(`${module}/flushPlottingFilters`)
    })

    return {
      getPlottingDetail,
      plottingList,
      moment,
      selectedTrayek,
      placeholder,
      inputSearch,
      icon,
      onSearch,
      store,
      filterDate,
      trayekList,
      listFilterTrayek,
      listFilterClass,
      module,
      onSubmitPlotting,
      paginate,
      onChangePage,
      selectedConductor,
      isEmpty,
      getDetailRosterPariwisata,
      onDeactiveRosterAction,
      showModalForm,
      onChangeStatusRosterAction,
      hideModalForm,
      modalConfirmation,
      hideConfirmDialog,
      showConfirmDialog,
      submitFromDialog,
      refreshPage,
      lastUpdate,
      generateRosterAction,
      plottingTableProp,
      nextPagePlotting,
      isEdit,
      progressSubmitPlotting,
      sidebarFormState,
      onChangeVehicleRosterAction,
      reloadFromVehicle,
      rosterPreview,
      onChangeStatusActiveRosterAction,
      currentKondekturRoster,
      listFilterTol
    }
  }
})
