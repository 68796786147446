const statusApprovalOptions = () => [
  {
    name: 'Semua Status',
    val: 0
  },
  {
    name: 'Approved',
    val: 1
  },
  {
    name: 'Not-Approved',
    val: 2
  }
]

export default statusApprovalOptions