
import { defineComponent, getCurrentInstance, onMounted, ref, watch } from 'vue'
import Card from 'primevue/card'
import apiUseCase from '@/usecase/apiUseCase'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'VerbalDetail',
  components: {
    Card
  },
  setup() {
    const app = getCurrentInstance()
    const store = useStore()
    const rit = ref(1)
    // const endpoint = 'transaction/v1/Dashboard/PendapatanPerBusBerjalan'
    const endpoint = 'transaction/v1/Dashboard/BusDashboardIncome'
    const dataSource = ref([]) as any
    const isLoading = ref(true)
    const {
      $toast, $numbers, $socketHub
    } = app!.appContext.config.globalProperties

    const getRit1 = async () => {
      await apiUseCase.get(`${endpoint}?pageNumber=1`).then((response) => {
        console.log('responseXXX', response.result)
        dataSource.value = response.result.filter((data: any) => data.RitOrder === 1)
        console.log('dataSource', dataSource.value)
        isLoading.value = false
      })
    }

    const currencyNumberFormat = (val: any) => `Rp. ${Number(val).toLocaleString('id-ID')}`

    watch(() => store.getters.getReloadDashboardRit1Table, (val) => {
      if (val) {
        console.log('1111')
        // isLoading.value = true
        getRit1()
      }
    })

    onMounted(() => {
      getRit1()
    })
    return {
      isLoading,
      dataSource,
      currencyNumberFormat
    }
  }
})
