
import { ref, toRefs } from 'vue'
import StatusApprovalOptions from '@/data/local/json/statusApprovalOptions'

export default {
  props: {
    selected: {
      default: () => 0
    },
  },
  emits: ['selectStatusApproval'],
  setup(props: any, { emit } : any) {
    const {
      selected
    } = toRefs(props)

    const options = ref(StatusApprovalOptions())

    const selectStatusAct = (evt: any, val: any) => {
      console.log('val', [evt, val])
      emit('selectStatusApproval', evt, val)
    }

    return {
      selectStatusAct,
      selected,
      options
    }
  }
}

