
import { computed, defineComponent, getCurrentInstance, onMounted, reactive, ref, watch } from 'vue'
import {
  IConductorForm
} from '@/utils/interfaces/iConductor'

import InputText from 'primevue/inputtext'
import InputSwitch from 'primevue/inputswitch'
import Button from 'primevue/button'
import Textarea from 'primevue/textarea'
import Card from 'primevue/card'
import vSelect from 'vue-select'
import Dropdown from 'primevue/dropdown'
import Password from 'primevue/password'
import ButtonFileUpload from '@/views/components/button/FileUpload.vue'
import { DatePicker } from 'v-calendar'
import { Form, Field } from 'vee-validate'
import * as Yup from 'yup'
import moment from 'moment'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import apiUseCase from '@/usecase/apiUseCase'
import IResponse from '@/utils/interfaces/iResponse'
import returnUrlPrams from '@/utils/helpers/returnUrlPrams'
import environment from '@/configs/environtment'
import ModalDefault from '@/views/components/modal/ModalDefault.vue'
import VOtpInput from 'vue3-otp-input'
import rgxExp from '@/utils/helpers/regExp'
import HjpConfirmationModal from '@/views/components/modal/HjpConfirmationModal.vue'
import SelectFetch from '@/views/components/form/SelectAsync.vue'
import InlineSvg from 'vue-inline-svg'
import { isNull } from 'lodash'
import { VueEternalLoading, LoadAction } from '@ts-pro/vue-eternal-loading'
import ProgressSpinner from 'primevue/progressspinner'

const endpointSignUp = '/management/v1/Signup'
const endpointBankCredential = '/management/v1/MsCredentialBank'
const endpointUser = '/management/v1/User/all-user'

const ConductorForm = defineComponent({
  name: 'ConductorForm',
  components: {
    Card,
    InputText,
    InputSwitch,
    Button,
    DatePicker,
    // vSelect,
    Dropdown,
    Password,
    Form,
    Field,
    ButtonFileUpload,
    Textarea,
    ModalDefault,
    VOtpInput,
    HjpConfirmationModal,
    InlineSvg,
    SelectFetch
    // VueEternalLoading,
    // ProgressSpinner
    // InputMask
  },
  setup() {
    const app = getCurrentInstance()
    const {
      $toast
    } = app!.appContext.config.globalProperties
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const isEdit = ref<boolean>(false)
    const showDialogOtp = ref<boolean>(false)
    const optionGender = ref<any[]>([
      { label: 'Laki-Laki', value: 'Pria' },
      { label: 'Perempuan', value: 'Perempuan' },
    ])
    const optionVaccines = ref<any[]>([
      { label: 'Vaksin 1', value: 1 },
      { label: 'Vaksin 2', value: 2 },
      { label: 'Vaksin 3', value: 3 }
    ])
    const maritalStatusOption = ref<any[]>([
      { label: 'Lajang', value: 'Lajang' },
      { label: 'Menikah', value: 'Menikah' },
      { label: 'Cerai', value: 'Cerai' },
    ])
    const optionCredentialBank = ref<any[]>([])
    const tempImgProfile = ref<any | null>(null)
    const tempImgNikPicture = ref<any | null>(null)
    const tempImgVaccineCertificate = ref<any | null>(null)
    const isCondectur = ref<boolean>((route.name as string).includes('conductor-account'))
    const routeName = (route.name as string).replace('-form', '')
    const condecturOrController = isCondectur.value ? 'Kondektur' : 'Kontroller'
    const role = ref(null) as any
    const otpInputValue = ref('')
    const otpInput = ref<InstanceType<typeof VOtpInput> | null>(null)
    const prevPhoneNumber = ref(null)
    const nfc = ref(null) as any
    const otpId = ref(null) as any
    const otpToken = ref(null) as any

    const tempLengthOfCredentialList = ref(0)
    const pageNumberCredential = ref(1)
    const isInitialCredential = ref(false)/** variable yang digunakan untuk mereset state menggunakan plugin vue-eternal-loading */
    const stopLoadCredential = ref(false)
    const isLoading = ref(false)

    const initialValues = ref<IConductorForm>({
      Username: null,
      Password: null,
      PhoneNumber: '',
      Email: '',
      Fullname: '',
      Address: '',
      IdCardNumber: '',
      PlaceOfBirth: '',
      DateOfBirth: null,
      Sex: optionGender.value[0].value,
      NIK: '',
      NIKPicture: null,
      VaccineCertificate: null,
      VaccineNumber: optionVaccines.value[0].value,
      MaritalStatus: maritalStatusOption.value[0].value,
      MsCredentialBankId: null,
      EmergencyContact: null,
      EmergencyAddress: null,
      EmergencyPhone: null,
      ProfilePicture: null,
      IsActive: true,
      NfcTag: null
    })

    const schemaValidation = Yup.object().shape({
      Username: Yup.string().required('Username tidak boleh kosong')
        .max(30, 'Username maksimal 30 karakter')
        .min(5, 'Username minimal 5 karakter')
        .matches(rgxExp.notSpaceChar, 'Username tidak boleh menggunakan spasi')
        .test('unique-username', 'Username sudah digunakan', async (val) => {
          if (!val) return true
          try {
            const res = await apiUseCase.get(`${endpointUser}${returnUrlPrams({
              search: val,
              filterField: ['username'],
              isSearcLowerCase: false
            })}`)
            const filterResult = res.result.filter((x: any) => x.Username === val)
            if (route.params?.id) {
              return filterResult.length === 0 || val === initialValues.value.Username
            } return filterResult.length === 0
          } catch (error) {
            return false
          }
        }),
      Password: Yup.string()
        .min(8, 'Password minimal 8 karakter')
        .max(100, 'Password maksimal 100 karakter')
        /* eslint-disable-next-line */
        .when('Username', ([], schema) => {
          return route.params?.id ? schema : schema.required('Password tidak boleh kosong')
        }),
      PhoneNumber: Yup.string()
        .min(11, 'Nomor Telepon minimal 11 karakter')
        .max(14, 'Nomor Telepon maksimal 14 karakter')
        .matches(rgxExp.phonePrefixZero, 'Nomor Telepon harus diawali angka 0.')
        .required('Nomor Telepon tidak boleh kosong')
        .test('unique-phonenumber', 'Nomor Telepon sudah digunakan', async (val) => {
          if (!val) return true
          try {
            const res = await apiUseCase.get(`${endpointUser}${returnUrlPrams({
              search: val,
              filterField: ['phoneNumber']
            })}`)
            const filterResult = res.result.filter((x: any) => x.PhoneNumber === val)
            if (route.params?.id) {
              return filterResult.length === 0 || val === initialValues.value.PhoneNumber
            } return filterResult.length === 0
          } catch (error) {
            return false
          }
        }),
      Email: Yup.string().required('Email tidak boleh kosong')
        .email('Gunakan format email yang benar')
        .max(100, 'Email maksimal 100 karakter')
        .test('unique-email', 'Email sudah digunakan', async (val) => {
          if (!val) return true
          try {
            const res = await apiUseCase.get(`${endpointUser}${returnUrlPrams({
              search: val,
              filterField: ['email']
            })}`)
            const filterResult = res.result.filter((x: any) => x.Email === val)
            if (route.params?.id) {
              return filterResult.length === 0 || val === initialValues.value.Email
            } return filterResult.length === 0
          } catch (error) {
            return false
          }
        }),
      Fullname: Yup.string()
        .required('Nama Lengkap tidak boleh kosong')
        .max(100, 'Nama Lengkap tidak boleh melebihi 100 karakter'),
      Address: Yup.string()
        .required('Alamat Domisili tidak boleh kosong')
        .max(400, 'Alamat Domisili tidak boleh melebihi 400 karakter'),
      IdCardNumber: Yup.string()
        .required(['conductor-account', 'controller-account'].includes(routeName) ? 'Kode Karyawan tidak boleh kosong' : 'No. ID Card tidak boleh kosong')
        .max(7, ['conductor-account', 'controller-account'].includes(routeName) ? 'Kode Karyawan tidak boleh melebihi 7 karakter' : 'No. ID Card tidak boleh melebihi 7 karakter')
        .test('unique-idcardnumber', ['conductor-account', 'controller-account'].includes(routeName) ? 'Kode Karyawan sudah digunakan' : 'No. ID Card sudah digunakan', async (val) => {
          if (!val) return true
          try {
            const res = await apiUseCase.get(`${endpointUser}${returnUrlPrams({
              search: val,
              filterField: ['idCardNumber']
            })}`)
            const filterResult = res.result.filter((x: any) => x.IdCardNumber === val)
            if (route.params?.id) {
              return filterResult.length === 0 || val === initialValues.value.IdCardNumber
            } return filterResult.length === 0
          } catch (error) {
            return false
          }
        }),
      NIK: Yup.string()
        .min(16, 'Minimal No. KTP/SIM minimal 16 karakter')
        .required('No. KTP/SIM tidak boleh kosong')
        .matches(rgxExp.notSpaceChar, 'No. KTP/SIM tidak boleh menggunakan spasi')
        .test('unique-ktp/sim', 'No. KTP/SIM sudah digunakan', async (val) => {
          if (!val) return true
          try {
            const res = await apiUseCase.get(`${endpointUser}${returnUrlPrams({
              search: val,
              filterField: ['nik']
            })}`)
            const filterResult = res.result.filter((x: any) => x.NIK === val)
            if (route.params?.id) {
              return filterResult.length === 0 || val === initialValues.value.NIK
            } return filterResult.length === 0
          } catch (error) {
            return false
          }
        }),
      MaritalStatus: Yup.string()
        .required('Status Pernikahan tidak boleh kosong'),
      DateOfBirth: Yup.string()
        .required('Tanggal Lahir tidak boleh kosong'),
      PlaceOfBirth: Yup.string()
        .required('Tempat Lahir tidak boleh kosong')
        .max(30, 'Tempat Lahir maksimal 30 karakter'),
      MsCredentialBankId: Yup.object()
        /* eslint-disable-next-line */
        .when('Email', ([], schema) => {
          return !isCondectur.value ? schema.nullable() : schema.required('Credential ID Bank tidak boleh kosong')
        }),
      EmergencyPhone: Yup.string()
        // .min(10, 'Telp Kontak Darurat minimal 10 karakter')
        .test('min-emergencyPhone', 'Telp Kontak Darurat minimal diisi 10 angka', async (val) => {
          if (!val) return true
          return val.length > 10
        }).nullable()
        .max(14, 'Telp Kontak Darurat maksimal 14 karakter')
        .matches(rgxExp.phonePrefixZero, 'Telp Kontak Darurat harus diawali angka 0.'),
      EmergencyContact: Yup.string().nullable()
        .max(100, 'Nama Lengkap tidak boleh melebihi 100 karakter')
    })

    const attrModalConfirmation = reactive({
      show: false,
      title: `Simpan Akun ${role.value}?`,
      subTitle: 'Pastikan data yang Anda tambahkan sudah sesuai dan tidak ada perubahan ditengah-tengah.',
      btnSubmitTitle: `Simpan Akun ${role.value}`,
      imgContent: isCondectur.value ? require('@/assets/img/modal-confirmation/conductor-save.svg') : require('@/assets/img/modal-confirmation/controller-save.svg')
    })

    const hideDialogConfirmation = (): void => {
      attrModalConfirmation.show = false
    }

    const onBack = () => {
      router.push({
        name: routeName
      })
    }

    const processUpload: string | null | any = async (file: any) => {
      const payload = new FormData()
      payload.append('file', file)
      const response = await apiUseCase.postOrPut('media/Upload', null, payload)
      if (!response.error) {
        return response.result.Filename
      }
      return null
    }

    const dataForm = ref<IConductorForm | null | any>(null)
    const processSubmit = async () => {
      try {
        let ProfilePictureTemp = null
        let VaccineCertificateTemp = null
        let NIKPictureTemp = null
        let messageSuccess = `Berhasil buat ${condecturOrController.toLocaleLowerCase()}`
        if (dataForm.value.ProfilePicture && typeof dataForm.value.ProfilePicture === 'object') {
          ProfilePictureTemp = await processUpload(dataForm.value.ProfilePicture.file)
        }
        if (dataForm.value.VaccineCertificate && typeof dataForm.value.VaccineCertificate === 'object') {
          VaccineCertificateTemp = await processUpload(dataForm.value.VaccineCertificate.file)
        }
        if (dataForm.value.NIKPicture && typeof dataForm.value.NIKPicture === 'object') {
          NIKPictureTemp = await processUpload(dataForm.value.NIKPicture.file)
        }
        console.log([typeof dataForm.value.ProfilePicture === 'object', dataForm.value.ProfilePicture])
        // Payload file when edit
        if (route.params?.id) {
          if (tempImgProfile.value && !dataForm.value.ProfilePicture) {
            ProfilePictureTemp = typeof dataForm.value.ProfilePicture === 'object' ? tempImgProfile.value.filePath : dataForm.value.ProfilePicture
          }
          if (tempImgNikPicture.value && !dataForm.value.NIKPicture) {
            NIKPictureTemp = typeof dataForm.value.NIKPicture === 'object' ? tempImgNikPicture.value.filePath : dataForm.value.NIKPicture
          }
          if (tempImgVaccineCertificate.value && !dataForm.value.VaccineCertificate) {
            VaccineCertificateTemp = typeof dataForm.value.VaccineCertificate === 'object' ? tempImgVaccineCertificate.value.filePath : dataForm.value.VaccineCertificate
          }
        }
        let rolename = ''
        if (role.value === 'Kontroler') {
          rolename = 'Controller'
        } else if (role.value === 'Kondektur') {
          rolename = 'Condectur'
        } else if (role.value === 'Sopir') {
          rolename = 'Driver'
        } else if (role.value === 'Pembantu') {
          rolename = 'Helper'
        }

        const payload = await {
          ...dataForm.value,
          ProfilePicture: ProfilePictureTemp ?? dataForm.value.ProfilePicture,
          VaccineCertificate: VaccineCertificateTemp ?? dataForm.value.VaccineCertificate,
          NIKPicture: NIKPictureTemp ?? dataForm.value.NIKPicture,
          MsCredentialBankId: isCondectur.value ? dataForm.value.MsCredentialBankId.value : null,
          // RolesName: isCondectur.value ? 'Condectur' : 'Controller'
          RolesName: rolename
        }

        if (route.params.id) {
          delete payload.Username
          delete payload.Password
          messageSuccess = `Berhasil mengubah data ${condecturOrController.toLocaleLowerCase()}`
        }
        console.log('payload', payload)
        const response: IResponse = await apiUseCase.postOrPut(endpointSignUp, route.params.id, payload)
        // console.log('response-submit', response)
        if (!response.error) {
          $toast.add({
            severity: 'success',
            detail: messageSuccess,
            group: 'bc',
            closable: false,
            life: 3000
          })
          router.push({
            name: routeName
          })
          store.dispatch('hideLoading')
        } else {
          $toast.add({
            severity: 'error',
            detail: response.message,
            group: 'bc',
            closable: false,
            life: 3000
          })
          store.dispatch('hideLoading')
        }
      } catch (error) {
        console.log('error', error)
        store.dispatch('hideLoading')
      }
    }

    const secondsLeft = ref(0)
    const phoneNumberTemp = ref(null)
    let interval = null as any

    function countDown(seconds: number) {
      secondsLeft.value = seconds
      interval = setInterval(() => {
        if (secondsLeft.value <= 0) {
          clearInterval(interval)
        } else {
          secondsLeft.value--
        }
      }, 1000)
    }

    const requestOtp = (payload: any) => {
      try {
        store.dispatch('requestOtp', payload).then((resx) => {
          if (resx.status) {
            otpId.value = resx.data.id ?? resx.data.Id
            if (!showDialogOtp.value) {
              showDialogOtp.value = true
            }
            phoneNumberTemp.value = payload.phoneNumber
            countDown(120)
          } else {
            $toast.add({
              severity: 'error',
              detail: resx.statusText ?? 'Gagal mengirim kode OTP. Silahkan coba beberapa saat lagi.',
              group: 'bc',
              closable: false,
              life: 3000
            })
          }
        })
      } catch (error) {
        $toast.add({
          severity: 'error',
          detail: 'Gagal mengirim kode OTP. Silahkan coba beberapa saat lagi.',
          group: 'bc',
          closable: false,
          life: 3000
        })
      }
      // store.dispatch('requestOtp', payload).then((resx) => {
      //   console.log('resx', resx)
      //   otpId.value = resx.data.Id
      //   if (!showDialogOtp.value) {
      //     showDialogOtp.value = true
      //   }
      //   phoneNumberTemp.value = payload.phoneNumber
      //   countDown(12)
      // }).catch((errx) => {
      //   console.log(errx)
      //   $toast.add({
      //     severity: 'error',
      //     detail: errx.response?.data?.detail ?? 'Gagal mengirim kode OTP. Silahkan coba beberapa saat lagi.',
      //     group: 'bc',
      //     closable: false,
      //     life: 3000
      //   })
      // })
    }

    const getTokenOtp = (resend = false) => {
      store.dispatch('showLoading')
      clearInterval(interval)
      // const token = store.state.otp.token
      console.log(moment(store.state.otp.expDate).diff(moment(), 'seconds'))
      if (store.state.otp.token && store.state.otp.expDate > moment() && phoneNumberTemp.value === dataForm.value.PhoneNumber && !resend) {
        const timeCount = moment(store.state.otp.expDate).diff(moment(), 'seconds')
        // console.log('aaa')
        console.log('timeCount', timeCount)
        // const payload = {
        //   token: store.state.otp.token,
        //   phoneNumber: dataForm.value.PhoneNumber,
        // }
        // requestOtp(payload)
        if (!showDialogOtp.value) {
          showDialogOtp.value = true
        }
        countDown(timeCount)
      } else if (store.state.otp.token && store.state.otp.expDate > moment() && (phoneNumberTemp.value !== dataForm.value.PhoneNumber || resend)) {
        const payload = {
          token: store.state.otp.token,
          // phoneNumber: '0895320500109'
          phoneNumber: dataForm.value.PhoneNumber,
        }
        requestOtp(payload)
        // if (!showDialogOtp.value) {
        //   showDialogOtp.value = true
        // }
        // countDown(120)
        store.dispatch('hideLoading')
      } else {
        store.dispatch('getTokenOtp').then((res) => {
          store.dispatch('otp/setExpdateOtp', moment().add(2, 'minutes'))
          store.dispatch('otp/setTokenOtp', res.data.access_token)
          otpToken.value = res.data.access_token
          const payload = {
            token: res.data.access_token,
            // phoneNumber: '0895320500109'
            phoneNumber: dataForm.value.PhoneNumber,
          }
          requestOtp(payload)
          // if (!showDialogOtp.value) {
          //   showDialogOtp.value = true
          // }
          // countDown(120)
          store.dispatch('hideLoading')
        }).catch((err) => {
          console.log('err', err)
          if (err?.response) {
            $toast.add({
              severity: 'error',
              detail: err.response.data.error,
              group: 'bc',
              closable: false,
              life: 3000
            })
          }
          store.dispatch('hideLoading')
          console.log('err on submit', err)
        })
      }
      // phoneNumberTemp.value = dataForm.value.PhoneNumber
      store.dispatch('hideLoading')
    }

    const onSubmit = (val: any) => {
      console.log('onSubmit', val)
      attrModalConfirmation.title = `Simpan Akun ${role.value}?`
      attrModalConfirmation.btnSubmitTitle = `Simpan Akun ${role.value}?`
      dataForm.value = val
      // eslint-disable-next-line no-use-before-define
      // handleChangeX(dataForm.value.NfcTag)
      dataForm.value.NfcTag = nfc.value ?? val.NfcTag
      console.log('dataForm', dataForm.value)
      attrModalConfirmation.show = true
      // if (route.params.id && (prevPhoneNumber.value === dataForm.value.PhoneNumber)) {
      //   attrModalConfirmation.show = true
      // } else {
      //   console.log('else')
      //   getTokenOtp()
      // }
    }

    const onSubmitConfirmation = (): void => {
      store.dispatch('showLoading')
      processSubmit()
    }

    const processValidationOtp = (): void => {
      store.dispatch('showLoading')
      store.dispatch('validateOtp', {
        id: otpId.value,
        otp: otpInputValue.value,
        token: otpToken.value
      }).then((res) => {
        console.log('resValid', res)
        processSubmit()
        store.dispatch('hideLoading')
      }).catch((err) => {
        if (err.response) {
          $toast.add({
            severity: 'error',
            summary: 'Gagal!',
            detail: err.response.data.detail,
            group: 'bc',
            closable: false,
            life: 3000
          })
        }
        store.dispatch('hideLoading')
      })
    }

    const submitOtp = () => {
      store.dispatch('showLoading')
      if (otpInputValue.value) {
        processValidationOtp()
      } else {
        $toast.add({
          severity: 'error',
          detail: 'OTP tidak boleh kosong!',
          group: 'bc',
          closable: false,
          life: 3000
        })
      }
      store.dispatch('hideLoading')
    }

    const onCompleteOtp = (val: any) => {
      console.log('onCompleteOtp', val)
    }

    const onInputFile = (file: any, typeUpload: string, setFieldValue: any) => {
      /* eslint-disable */
      if (file) {
        const totalSize: number = Number((file.size / 1024 / 1024).toFixed(1))
        if (totalSize > 1) {
          $toast.add({
            severity: 'error',
            detail: 'Ukuran file lebih dari 1MB.',
            group: 'bc',
            closable: false,
            life: 3000
          })
        } else {
          /* eslint-disable-next-line */
          if (file) {
            if (typeUpload === 'photo-profile') {
              // tempImgProfile.value = file.blob
              setFieldValue('ProfilePicture', { file: file.file, name: file.name, blob: file.blob })
            }
            if (typeUpload === 'vaccine-certificate') {
              // tempImgVaccineCertificate.value = file
              setFieldValue('VaccineCertificate', { file: file.file, name: file.name, blob: file.blob })
            }
            if (typeUpload === 'ktp/sim') {
              // tempImgNikPicture.value = file
              setFieldValue('NIKPicture', { file: file.file, name: file.name, blob: file.blob })
            }
          }
        }
      }
    }

    const onSearchBankCredential = async (search: string) => {
      await apiUseCase.get(`${endpointBankCredential}${returnUrlPrams({
        search,
        pageNumber: pageNumberCredential.value,
        filterField: ['bankName'],
        custom: [['activeOnly', '=', 'true']]
      })}`).then((res: IResponse) => {
        tempLengthOfCredentialList.value = res.result.length
        const optionCredentialBankTemp = res.result.map((val: any) => ({
          ...val,
          label: `${val.IdCorporate}`,
          value: val.Id,
        }))
        optionCredentialBankTemp.forEach((x: any) => optionCredentialBank.value.push(x))
      })
    }

    const maxSizeFile = 1024 * 1024 * 1

    const onUpdateBirthDate = (val: any) => {
      console.log('onUpdateBirthDate', val)
    }

    const getFileName = (path: string) => path.substring(path.lastIndexOf('/') + 1)

    const getDetail = async () => {
      try {
        const { error, result }: IResponse = await apiUseCase.get(`/management/v1/User/single-user/${route.params.id}`)
        if (!error) {
          console.log('result', result)
          prevPhoneNumber.value = result.PhoneNumber
          console.log('phone', prevPhoneNumber.value)
          initialValues.value = {
            ...result,
            NfcTag: result?.NFCTag,
            MsCredentialBankId: {
              value: result?.MsCredentialBankId,
              label: result?.MsCredentialBank?.IdCorporate
            }
          }
          if (result?.ProfilePicture) {
            tempImgProfile.value = {
              fileName: getFileName(result.ProfilePicture),
              filePath: result.ProfilePicture,
              fileUrl: `${environment.getApiBaseUrl()}${result.ProfilePicture}`
            }
            console.log(tempImgProfile.value)
          }
          if (result?.VaccineCertificate) {
            tempImgVaccineCertificate.value = {
              fileName: getFileName(result.VaccineCertificate),
              filePath: result.VaccineCertificate,
              fileUrl: `${environment.getApiBaseUrl()}${result.VaccineCertificate}`
            }
          }
          if (result?.NIKPicture) {
            tempImgNikPicture.value = {
              fileName: getFileName(result.NIKPicture),
              filePath: result.NIKPicture,
              fileUrl: `${environment.getApiBaseUrl()}${result.NIKPicture}`
            }
          }
          isLoading.value = false
        }
      } catch (error) {
        $toast.add({
          severity: 'error',
          detail: 'User tidak tersedia',
          group: 'bc',
          closable: false,
          life: 3000
        })
        onBack()
      }
    }

    const openDetailImg = (fileUrl: string) => {
      window.open(fileUrl, '_blank')
    }

    const convertToHex = (val: any) => {
      let code = ''
      let codeTemp = null
      codeTemp = parseFloat(val)
      codeTemp = codeTemp.toString(16).toUpperCase()
      codeTemp = codeTemp.match(/.{1,2}/g)
      if (!isNull(val) && !isNull(codeTemp)) {
        for (let i = 0; i < codeTemp!.length; i++) {
          if (i == codeTemp!.length - 1) {
            code = codeTemp![i] + code
          } else {
            code = ':'+ codeTemp![i] + code
          }
          // console.log('code', code)
        }
      }
      return code
    }

    const handleChangeX = (val: any) => {
      console.log('val', val.target.value)
      if (!val.target.value.includes(':')) {
        const valNfc = val.target.value
        const temp = convertToHex(valNfc)
        console.log('converted', temp)
        initialValues.value.NfcTag = temp
        nfc.value = temp
      } else {
        nfc.value = val.target.value
      }
    }

    const loadDataCredential = async ({ loaded, noMore }: LoadAction) => {
      console.log('aaa', tempLengthOfCredentialList.value)
      if (tempLengthOfCredentialList.value >= 10 && !stopLoadCredential.value) {
        pageNumberCredential.value += 1
        onSearchBankCredential('').then((x: any) => {
          if (tempLengthOfCredentialList.value < 10) {
            console.log('<10', tempLengthOfCredentialList.value)
            stopLoadCredential.value = true
            /** menggunakan fungsi unutuk mereset state data kosong type dri interface plugin vue-eternal-loading */
            noMore()
          } else {
            console.log('xxx')
            /** menggunakan fungsi unutuk load state type dri interface plugin vue-eternal-loading */
            loaded()
          }
        })
      } else {
        noMore()
      }
    }

    const initRole = () => {
      console.log('aaa', route.name)
      if (route.name === 'conductor-account-form') {
        role.value = 'Kondektur'
      } else if (route.name === 'controller-account-form') {
        role.value = 'Kontroler'
      } else if (route.name === 'driver-account-form') {
        console.log('aaa')
        role.value = 'Sopir'
      } else if (route.name === 'helper-account-form') {
        role.value = 'Pembantu'
      }
    }

    onMounted(() => {
      onSearchBankCredential('')
      initRole()
      let title = `Tambah Akun ${role.value}`
      isLoading.value = true
      if (route.params.id) {
        title = `Edit Akun ${role.value}`
        isEdit.value = true
        getDetail()
      } else {
        isLoading.value = false
      }
      store.dispatch('ui/setBreadcrumbs', [
        {
          title: `Akun ${role.value}`,
          routeName
        },
        {
          title,
          routeName: `${route.name as string}`
        }
      ])
    })

    watch(() => showDialogOtp.value, (val: boolean) => {
      // console.log('vallin', val)
      if (!val) {
        otpInputValue.value = ''
      }
    })
    return {
      optionGender,
      onBack,
      initialValues,
      schemaValidation,
      onSubmit,
      onInputFile,
      tempImgProfile,
      maxSizeFile,
      onUpdateBirthDate,
      moment,
      optionVaccines,
      optionCredentialBank,
      maritalStatusOption,
      onSearchBankCredential,
      isEdit,
      condecturOrController,
      showDialogOtp,
      otpInputValue,
      submitOtp,
      onCompleteOtp,
      otpInput,
      tempImgVaccineCertificate,
      tempImgNikPicture,
      dataForm,
      getTokenOtp,
      secondsLeft,
      attrModalConfirmation,
      hideDialogConfirmation,
      onSubmitConfirmation,
      isCondectur,
      openDetailImg,
      handleChangeX,
      nfc,
      loadDataCredential,
      isInitialCredential,
      isLoading,
      role,
      getFileName
      // selectFile
    }
  }
})
export default ConductorForm
