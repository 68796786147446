/* eslint-disable class-methods-use-this */
import Configuration from '@/configs/env_configuration'

class Environment {
  setEnv(processDev: any, processProd: any): any {
    // console.log('in set env', processDev, processProd)
    if (processProd !== null) {
      return processProd
    } return processDev
  }

  getApiBaseUrl() {
    return this.setEnv(`${process.env.VUE_APP_API_BASE_URL}`, Configuration.value('API_BASE_URL'))
  }

  getApiBasePath() {
    return this.setEnv(`${process.env.VUE_APP_API_BASE_PATH}`, Configuration.value('API_BASE_PATH'))
  }

  getApiUrl() {
    return this.getApiBaseUrl()
  }

  getVersion() {
    return this.setEnv(`${process.env.VUE_APP_VERSION}`, Configuration.value('VERSION'))
  }

  getApiReporting() {
    return this.setEnv(process.env.VUE_APP_API_REPORTING, Configuration.value('API_REPORTING'))
  }

  getApiUrlHjx() {
    return this.setEnv(process.env.VUE_APP_API_URL_HJX, Configuration.value('API_URL_HJX'))
  }

  getHydraApi() {
    return this.setEnv(process.env.VUE_APP_HYDRA_API, Configuration.value('HYDRA_API'))
  }

  getHydraClientId() {
    return this.setEnv(process.env.VUE_APP_HYDRA_CLIENT_ID, Configuration.value('HYDRA_CLIENT_ID'))
  }

  getHydraScope() {
    return this.setEnv(process.env.VUE_APP_HYDRA_SCOPE, Configuration.value('HYDRA_SCOPE'))
  }

  getHydraResponseType() {
    return this.setEnv(process.env.VUE_APP_HYDRA_RESPONSE_TYPE, Configuration.value('HYDRA_RESPONSE_TYPE'))
  }

  getHydraState() {
    return this.setEnv(process.env.VUE_APP_HYDRA_STATE, Configuration.value('HYDRA_STATE'))
  }

  getHydraClientSecret() {
    return this.setEnv(process.env.VUE_APP_HYDRA_CLIENT_SECRET, Configuration.value('HYDRA_CLIENT_SECRET'))
  }

  getHydraGrantType() {
    return this.setEnv(process.env.VUE_APP_HYDRA_GRANT_TYPE, Configuration.value('HYDRA_GRANT_TYPE'))
  }

  getOtpTokenBaseUrl() {
    return this.setEnv(process.env.VUE_APP_OTP_TOKEN_BASE_URL, Configuration.value('OTP_TOKEN_BASE_URL'))
  }

  getOtpBaseUrl() {
    return this.setEnv(process.env.VUE_APP_OTP_BASE_URL, Configuration.value('OTP_BASE_URL'))
  }

  getOtpGrantType() {
    return this.setEnv(process.env.VUE_APP_OTP_BASE_URL, Configuration.value('OTP_GRANT_TYPE'))
  }

  getOtpScope() {
    return this.setEnv(process.env.VUE_APP_OTP_BASE_URL, Configuration.value('OTP_SCOPE'))
  }

  getOtpClientId() {
    return this.setEnv(process.env.VUE_APP_OTP_BASE_URL, Configuration.value('OTP_CLIENT_ID'))
  }

  getOtpClientSecret() {
    return this.setEnv(process.env.VUE_APP_OTP_BASE_URL, Configuration.value('OTP_CLIENT_SECRET'))
  }

  getUsernameMqtt() {
    return this.setEnv(process.env.VUE_APP_USERNAME_MQTT, Configuration.value('USERNAME_MQTT'))
  }

  getPasswordMqtt() {
    return this.setEnv(process.env.VUE_APP_PASSWORD_MQTT, Configuration.value('PASSWORD_MQTT'))
  }

  getUrlBaseMqtt() {
    return this.setEnv(process.env.VUE_APP_URL_BASE_MQTT, Configuration.value('URL_BASE_MQTT'))
  }

  getPortBaseMqtt() {
    return this.setEnv(process.env.VUE_APP_PORT_BASE_MQTT, Configuration.value('PORT_BASE_MQTT'))
  }

  getProtocolBaseMqtt() {
    return this.setEnv(process.env.VUE_APP_PROTOCOL_BASE_MQTT, Configuration.value('PROTOCOL_BASE_MQTT'))
  }
}

const environment = new Environment()

export default environment
